import { IInput } from "../../../interface/input/input";
import { IInputConstructor } from "../../../interface/constructor/input/input-constructor";
import { mandatoryString, undefinedAttribute } from "../../tool/setter";

/**
 * Classe abstraite relative aux diverses caractéristiques de base que peut comporter un input.
 * 
 * @property _value *(T)* : Correspond à la valeur saisie par l'utilisateur.
 * @property _key *(string)* : Correspond à la clé permettant d'identifier un input.
 * @property _label *(string)* : Correspond au label associé à l'input.
 * @property _isRequired *(boolean)* : Correspond au statut de requirement d'un input, i.e. si l'input soit obligatoirement être renseigné.
 * @property _error *(string)* : Correspond à l'erreur courante associée à l'input.
 * 
 * @template T Le type associé à la valeur d'un input.
 */
export abstract class Input<T> implements IInput<T> {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    /** Correspond à la valeur saisie par l'utilisateur. */
    protected _value!: T;

    /** Correspond à la clé permettant d'identifier un input. */
    protected _key!: string;

    /**  Correspond au label associé à l'input. */
    protected _label!: string;

    /** Correspond au statut de requirement d'un input, i.e. si l'input soit obligatoirement être renseigné. */
    protected _isRequired!: boolean;

    /** Correspond à l'erreur courante associée à l'input. */
    protected _error!: string;

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputConstructor<T>
    ) {
        this.setValue(options.value!);
        this.setKey(options.key);
        this.setLabel(options.label!);
        this.setIsRequired(options.isRequired!);
        this.setError(options.error!);
    }

    // --------------------------
    // Getter
    // --------------------------

    public getValue() : T
    { return this._value; }

    public getKey() : string 
    { return this._key; }

    public getLabel() : string 
    { return this._label; }

    public getIsRequired() : boolean 
    { return this._isRequired; }

    public getError() : string
    { return this._error; }

    // --------------------------
    // Setter
    // --------------------------

    public setValue(value: T) : void {
        this._value = value;
    }

    public setKey(key: string) : void {
        mandatoryString(key, this.getKey(), 'key');

        this._key = key.trim();
    }

    public setLabel(label: string = "") : void {
        this._label = label.trim();
    }

    public setIsRequired(isRequired: boolean = false) : void {
        this._isRequired = isRequired;
    }

    public setError(error: string = "") : void {
        this._error = error.trim();
    }

    // --------------------------
    // Méthode
    // --------------------------

    public validator() : void {
        // Si aucune valeur n'est entrée, on lève une exception
        undefinedAttribute(this._value, this.getKey(), 'value');
    }
}