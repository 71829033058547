import { ButtonProps } from '../../../interface/props/button/button-props';

/**
 * @brief Composant de bouton permettant certaines actions autre que la navigation.
 * @param props ButtonProps : Interface permettant de caractériser un bouton.
 * @returns Composant de bouton ayant la couleur secondaire permettant certaines actions autre que la navigation.
 * 
 * @prop {string} className : Classe CSS du bouton.
 * @prop {"button" | "submit" | "reset" | undefined} type : Type du bouton.
 * @prop {any} buttonRef : Référence vers le bouton de naviguation.
 * @prop {React.MouseEventHandler<HTMLButtonElement>} onClick : Action de clique associé au bouton.
 * @prop {any} children : Contenu du bouton.
 */
const SecondaryButton: React.FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
    const className: string = `button button-fill-secondary ${props.className}`;

    return (
        <button 
            type={props.type} 
            ref={props.buttonRef} 
            className={className}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}

export default SecondaryButton;