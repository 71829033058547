import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './import';
import AppContainer from './content/view/element/app-container';

const App = () => {
    return (
        <BrowserRouter>
            <AppContainer />

            <ToastContainer
                style={{
                    whiteSpace: "pre-wrap"
                }}
                progressStyle={{
                    background: "#74d6a1"
                }}
            />
        </BrowserRouter>
    );
}

export default App;
