import { cardTitle, cardsEn } from "../../../text-resources/home-page/home-cards";
import { SectionTitle }from "../../component/text/title";
import { useInView } from "react-intersection-observer";
import CardContainer from "../../component/container/card-container";

const HomeCards = () => {
    const { ref, inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    const titleClassName:string = `space-title ${(inViewClassName)}`;

    return (
        <section ref={ref} className="space-element-extra-large">
            <SectionTitle
                className={titleClassName}
                title={ cardTitle }
            />
            <CardContainer cards={cardsEn}/>
        </section>
    );
}

export default HomeCards;