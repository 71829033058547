/**
 * @brief Fonction permettant de concaténer des expressions régulières.
 * 
 * @param patterns L'ensemble de regex à concaténer.
 * @returns Une Regex concaténée.
 */
export const concatRegex = (patterns: RegExp[]) : RegExp => {
    let concatRegex: string = "";
    
    for (let pattern of patterns) concatRegex += '(?=.*' + pattern.source + ')';
    
    return RegExp(concatRegex);
}