import { Title } from "../../component/text/title";
import museum from '../../../../assets/image/museum-visit.svg';
import TwoCellsGrid from "../../component/container/two-cells-grid";
import Text from "../../component/text/text";
import { header } from "../../../text-resources/home-page/home-header";
import { useInView } from "react-intersection-observer";
import PrimaryNavButton from "../../component/button/primary-nav-button";
import { InViewMotionCellProps } from "../../../interface/props/container/two-cells-grid-props";

const LeftCellContent: React.FC<InViewMotionCellProps> = (props: InViewMotionCellProps) => {
    const inViewClassName: string = (props.inView) 
        ? 'in-view-motion-show in-view-motion-to-top-or-bottom-show' 
        : 'in-view-motion-hidden in-view-motion-to-bottom-hidden'

    const textClassName: string = `header-text ${(inViewClassName)}`;
    const navButtonClassName: string = `${inViewClassName}`;

    return (
        <>
            <Title
                className="header-title"
                title={ header.title }
            />
            <hr className="header-rule"/>
            <Text 
                className={ textClassName }
                text={ header.text }
            />
            <PrimaryNavButton
                className={ navButtonClassName }
                text={ header.button }
                to="/schedule"
            />
        </>
    );
}

const RightCellContent = () => {
    return (
        <img 
            src={ museum }
            className="header-image" 
            alt="Home header showing a museum visit." 
        />
    );
}

const HomeHeader = () => {
    const { ref , inView } = useInView({triggerOnce: true});

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    const rightCellClassName: string = `header-image-container ${inViewClassName}`;
    const leftCellClassName: string = `header-text-container ${(inViewClassName)}`;

    return (
        <header className="in-view-container space-element-extra-large">
            <TwoCellsGrid
                twoCellsGridRef={ ref }
                rightCellClassName={ rightCellClassName }
                leftCellClassName={ leftCellClassName }
                leftCellContent = { <LeftCellContent inView={inView}/> }
                rightCellContent = { <RightCellContent/> }
            />
        </header>
    );
}

export default HomeHeader;