import AboutHeader from "../element/about/about-header";
import AboutProblemExplanation from "../element/about/about-problem-explanation";
import AboutTeamPresentation from "../element/about/about-team-pres";
import Footer from "../element/footer";

const AboutScreen = () => {
    return (
        <>
            <AboutHeader/>
            <AboutTeamPresentation/>
            <AboutProblemExplanation/>
            <Footer/>
        </>
    );
}

export default AboutScreen;