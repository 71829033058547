import { NoteProps } from "../../../interface/props/card/note-props";
import Text from "../text/text";

/**
 * @brief Composant permettant d'afficher une note, et mettre en évidence certaines données.
 * @param props NoteProps : Interface permettant de caractériser une note 
 * @returns Composant permettant d'afficher une note.
 * 
 * @prop {string} className : Classe CSS de la note.
 * @prop {any} noteRef : Référence vers la note.
 * @prop {string} text : Texte de la note.
 */
const Note: React.FC<NoteProps> = (props: NoteProps): JSX.Element => {
    const className: string = `note-container ${props.className}`;

    return (
        <div ref={props.noteRef} className={ className }>
            <Text
                className="text-bold text-italic note-title"
                text="Note :"
            />
            <Text
                className="text-italic note-content"
                text={ props.text }
            />
        </div>
    );
}

export default Note;