/**
 * @brief Fonction permettant de générer une couleur aléatoire.
 * @returns Une couleur au format hexadécimal de manière aléatoire.
 */
export const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;


/**
 * @brief Fonction permettant de générer un tableau à trois dimensions.
 * @param size La taille du tableau à trois dimensions.
 * @returns Un tableau à trois dimensions.
 */
export const initTriDimensionamlArray = (size: number) : number[][][] => {
    let array: number[][][] = [];

    for (let i = 0 ; i < size ; i++) array[i] = [];

    return array;
}


/**
 * @brief Fonction permettant de transposer une matrice.
 * @param matrix La matrice à transposer.
 * @returns La matrice transposée.
 */
export const transpose = (matrix: number[][][] | number[][]) : void => {
    for (var i = 0; i < matrix.length; i++) 
        for (var j = 0; j < i; j++) 
            [matrix[i][j], matrix[j][i]] = [matrix[j][i], matrix[i][j]];
}