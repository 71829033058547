import Footer from "../element/footer";
import ScheduleForm from "../element/schedule/schedule-form";
import ScheduleHeader from "../element/schedule/schedule-header";

const ScheduleScreen = () => {
    return (
        <>
            <ScheduleHeader/>
            <ScheduleForm/>
            <Footer/>
        </>
    );
}

export default ScheduleScreen;