import { TitleProps } from '../../../interface/props/text/title-props';

/**
 * @brief Composant permettant d'afficher un titre (h1).
 * @param props TitleProps : Interface permettant de caractériser un titre
 * @returns Composant permettant d'afficher un titre.
 * 
 * @prop {string} title : Titre à afficher.
 * @prop {string} className : Classe CSS du conteneur du titre.
 * @prop {any} titleRef : Référence vers le composant HTML du titre.
 */
export const Title: React.FC<TitleProps> = (props: TitleProps): JSX.Element => {
    const className: string = `title-container ${props.className}`;

    return (
        <h1 ref={props.titleRef} className={className}>
            {props.title}
        </h1>
    );
}

/**
 * @brief Composant permettant d'afficher un titre de section (h2). 
 * @param props TitleProps : Interface permettant de caractériser un titre
 * @returns Composant permettant d'afficher un titre.
 * 
 * @prop {string} title : Titre à afficher.
 * @prop {string} className : Classe CSS du conteneur du titre.
 * @prop {any} titleRef : Référence vers le composant HTML du titre.
 */
export const SectionTitle: React.FC<TitleProps> = (props: TitleProps): JSX.Element => {
    const className: string = `title-container ${props.className}`;

    return (
        <h2 ref={props.titleRef} className={className}>
            {props.title}
        </h2>
    );
}

