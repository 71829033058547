import React from "react";
import { InputErrorProps } from "../../../interface/props/input/input-error-props";
import Text from "../text/text";

/**
 * @brief Composant permettant l'affichage d'un message d'erreur associé à une saisie d'utilisateur.
 * @param props InputErrorProps : Interface permettant de caractériser l'erreur d'un input
 * @returns Composant permettant l'affichage d'un message d'erreur associé à une saisie d'utilisateur.
 * 
 * @prop {string} errorMessage : Le message d'erreur à afficher.
 * @prop {React.RefObject} errorRef : La référence de l'erreur de l'input.
 */
const InputError: React.FC<InputErrorProps> = (props: InputErrorProps): JSX.Element | null => {
    const text: string = (props.errorMessage === "" || props.errorMessage === undefined || props.errorMessage === null) 
        ? " " 
        : props.errorMessage;

    return (
        <Text
            textRef={props.errorRef}
            className="text-warning "
            text={text}
        />
    );
}

export default InputError;