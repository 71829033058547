import React from "react";
import { InputLabelProps } from "../../../interface/props/input/input-label-props";
import { defaultToastBox } from "../../../controller/global/toast-box";
import SecondaryButton from "../button/secondary-button";

/**
 * @brief Composant permettant l'affichage du label d'une saisie.
 * @param props InputErrorProps : Interface permettant de caractériser le label d'un input 
 * @returns Composant permettant l'affichage du label d'une saisie.
 * 
 * @prop {InputType} input : L'input pour lequel on souhaite décorer le label.
 * @prop {boolean} displayInputConstraints : Détermine si on affiche ou non une aide pour comprendre ce que réalise l'input.
 * @prop {string} inputContraintsExplanationMessage : Le texte à afficher dans une box afin de mieux comprendre comment remplir l'input.
 */
const InputLabel: React.FC<InputLabelProps> = (props: InputLabelProps): JSX.Element => {
    return (
        <div className="input-label-container " >
            <label className="text-container text-italic text-primary" htmlFor={props.input.getKey()}>
                {props.input.getLabel()}
                {
                    (props.input.getIsRequired()) 
                        ? <span className="text-container text-warning"> *</span>
                        : null
                }
            </label>
            {
                (props.displayInputConstraints) 
                    ? <SecondaryButton 
                        className="button-information label-help-button"
                        onClick={() => defaultToastBox(props.inputContraintsExplanationMessage) }
                    > ! </SecondaryButton>
                    : <></>
            }
        </div>
    );
};

export default InputLabel;