import { InputTextComponentProps, InputTextareaComponentProps } from "../../../interface/props/input/input-component";
import InputBase from "./base/input-base";
import InputTextType from "./base/input-text-type";
import InputTextareaType from "./base/input-textarea-type";

/**
 * @brief Composant permettant l'affichage d'un input de type texte.
 * @param props InputTextComponentProps : Interface permettant de caractériser un input de type text
 * @returns Composant permettant l'affichage d'un input de type texte.
 * 
 * @prop {InputText} input : L'input de type text à utiliser.
 * @prop {string} classNameInputBase : La classe CSS associée à la base de l'input.
 * @prop {string} classNameInputText : La classe CSS associée à l'input texte.
 * @prop {boolean} displayInputConstraints : Détermine si on affiche ou non une aide pour comprendre ce que réalise l'input.
 * @prop {string} inputContraintsExplanationMessage : Le texte à afficher dans une box afin de mieux comprendre comment remplir l'input.
 * @prop {React.RefObject} errorRef : La référence de l'erreur de l'input.
 */
export const InputTextComponent: React.FC<InputTextComponentProps> = (props: InputTextComponentProps) => {
    return (
        <InputBase 
            className={props.classNameInputBase}
            input={props.input}
            displayInputConstraints={props.displayInputConstraints}
            inputContraintsExplanationMessage={props.inputContraintsExplanationMessage}
            errorRef={props.errorRef}
        >
            <InputTextType
                inputRef={props.inputRef}
                readonly={props.readonly}
                disabled={props.disabled}
                autofocus={props.autofocus}
                placeholder={props.placeholder}
                value={props.value}
                className={props.classNameInputText}
                onBlur={props.onBlur}
            />
        </InputBase>
    );
}

/**
 * @brief Composant permettant l'affichage d'une zone de texte.
 * @param props InputTextareaComponentProps : Interface permettant de caractériser une zone de texte
 * @returns Composant permettant l'affichage d'une zone de texte.
 * 
 * @prop {InputCsv} input : L'input de type text à utiliser.
 * @prop {string} classNameInputBase : La classe CSS associée à la base de l'input.
 * @prop {string} classNameInputText : La classe CSS associée à l'input texte.
 * @prop {boolean} displayInputConstraints : Détermine si on affiche ou non une aide pour comprendre ce que réalise l'input.
 * @prop {string} inputContraintsExplanationMessage : Le texte à afficher dans une box afin de mieux comprendre comment remplir l'input.
 * @prop {React.RefObject} errorRef : La référence de l'erreur de l'input.
 */
export const InputTextareaComponent: React.FC<InputTextareaComponentProps> = (props: InputTextareaComponentProps) => {
    return (
        <InputBase 
            className={props.classNameInputBase}
            input={props.input}
            displayInputConstraints={props.displayInputConstraints}
            inputContraintsExplanationMessage={props.inputContraintsExplanationMessage}
            errorRef={props.errorRef}
        >
            <InputTextareaType
                inputRef={props.inputRef}
                readonly={props.readonly}
                disabled={props.disabled}
                autofocus={props.autofocus}
                placeholder={props.placeholder}
                value={props.value}
                rows={props.rows}
                cols={props.cols}
                className={props.classNameInputTextarea}
            />
        </InputBase>
    );
}
