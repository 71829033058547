import { InputText } from "../../model/object/input/input-text";

/**
 * @brief Fonction permettant de gérer un évènement lorsque la valeur de l'input change.
 * @param event L'évènement.
 * @param input L'input à mettre à jour.
 * @param setError La fonction permettant de modifier l'erreur.
 */
export const onChangeInputTextType = (
    event: any, 
    input: InputText, 
    setError: React.Dispatch<React.SetStateAction<string>>
) => {
    // Mise à jour de la valeur de l'input lors du changement de la valeur saisie par l'utilisateur
    input!.setValue(event.target.value);
    
    // Mise à jour de l'erreur de l'input lors du changement de la valeur saisie par l'utilisateur
    setError!(input!.getError());
}