import { InputCsvInteger } from "../../model/object/input/input-csv-integer";
import { InputInteger } from "../../model/object/input/input-integer"
import { regexCsvNumber, regexNumber } from "../../model/variable/regex";

/**
 * @brief Fonction permettant de récupérer les données relatives à la saisie du nombre de groupes de visiteurs.
 * @returns Objet contenant les données relatives à la saisie du nombre de groupes de visiteurs. 
 */
export const getNbOfVisitorGroups = () : InputInteger => {
    return new InputInteger({
        label: "Enter number of visitor groups : ",
        key: "nb-visitor-groups",
        isRequired: true,
        specificPatten: [
            regexNumber.nonNegative
        ],
        value: '' 
    });
}

/**
 * @brief Fonction permettant de récupérer les données relatives à la saisie du nombre de salles à visiter.
 * @returns Objet contenant les données relatives à la saisie du nombre de salles à visiter.
 */
export const getNbOfRooms = () : InputInteger => {
    return new InputInteger({
        label: "Enter number of rooms to visit : ",
        key: "nb-rooms-to-visit",
        isRequired: true,
        specificPatten: [
            regexNumber.nonNegative
        ],
        value: ''
    });
}

/**
 * @brief Fonction permettant de récupérer les données relatives à la saisie des durées de visite des salles.
 * @returns Objet contenant les données relatives à la saisie des durées de visite des salles.
 * 
 * @todo ajouter une option de disable sur les csv tant que les valeurs des rooms et des groups ne sont pas renseignées
 * @todo ajouter une possible bulle d'aide ?
 */
export const getProcessingTimes = () : InputCsvInteger => {
    return new InputCsvInteger({
        nbOfColumns: 0,
        nbOfRows: 0,
        label: "Enter the durations that each groups should spend in a room : ",
        key: "processing-times",
        isRequired: true,
        specificPatten: [
            regexCsvNumber.nonNegative
        ],
    });
}

/**
 * @brief Fonction permettant de récupérer les données relatives à la saisie des dates de retour au point de départ des groupes de visiteurs.
 * @returns Objet contenant les données relatives à la saisie des dates de retour au point de départ des groupes de visiteurs.
 */
export const getDueDates = () : InputCsvInteger => {
    return new InputCsvInteger({
        nbOfColumns: 0,
        nbOfRows: 1,
        label: "Enter times to start point for each visitor group : ",
        key: "due-date",
        isRequired: true,
        specificPatten: [
            regexCsvNumber.nonNegative
        ],
    });
}