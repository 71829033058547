import { Adapter } from "../../../../interface/adapter/adapter";
import { IOpenShopScheduling } from "../../../../interface/adapter/client-to-api/open-shop-scheduling";
import { IScheduleForm } from "../../../../interface/input/schedule-form";
import { fromCsvTo2dNumberArray, fromCsvToNumberArray, fromStringToNumber } from "../../../../tool/format-data";

/**
 * Classe permettant d'adapter une taille avant envoie à la base de donner.
 */
export class ScheduleParamToApi implements Adapter<IOpenShopScheduling> {

    // -----------------
    // Déclaration des attributs
    // -----------------

    private static _instance: ScheduleParamToApi;

    // -----------------
    // Constructeur
    // -----------------

    private constructor() {}

    // -----------------
    // Getter
    // -----------------

    public static getInstance() : ScheduleParamToApi {
        if (!this._instance) 
            this._instance = new ScheduleParamToApi();

        return this._instance;
    }

    // -----------------
    // Méthode 
    // -----------------

    public adapt(data: IScheduleForm) : IOpenShopScheduling {
        return {
            nbOfJobs: fromStringToNumber(data.nbOfGroups.getValue()),
            nbOfMachines: fromStringToNumber(data.nbOfRooms.getValue()),
            processingTimes: fromCsvTo2dNumberArray(data.processingTimes.getValue()),
            dueDates: fromCsvToNumberArray(data.dueDates.getValue())            
        };
    }
}