import { Title }from "../../component/text/title";
import Text from "../../component/text/text";
import Note from "../../component/card/note";
import React from "react";
import { dataExample, explanationText, inputText, notes, titles } from "../../../text-resources/working-page/working-explanation";
import ScheduleVisualization from "../../component/schedule-visualization";
import { dueDateFormatArray, scheduleArrayFormat } from "../../../controller/global/schedule-visualization";
import { useInView } from "react-intersection-observer";
import TwoDimensionalTable from "../../component/table/two-dimensional-table";

interface InputExplanationProps {
    className?: string;
    inputText: string;
    labelText: string;
}

interface MotionContainerProps {
    children: any;
    className?: string;
}

const MotionContainer: React.FC<MotionContainerProps> = (props: MotionContainerProps) => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    const className: string = props.className + " " + inViewClassName;

    return (
        <div ref={ref} className={className}>
            {props.children}
        </div>
    );
}

const InputExplanation: React.FC<InputExplanationProps> = (props: InputExplanationProps) => {
    return (
        <div className={"input-explanation-container " + props.className}>
            <Text className="input-explanation-label text-italic" text={props.labelText} />
            <Text className="input-text" text={props.inputText} />
        </div>
    );
}

const WorkingExplanation = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-left-hidden';

    const inViewClassNameRight: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    let scheduleSolution = scheduleArrayFormat(dataExample.scheduleSolution);
    let dueDates = dueDateFormatArray(dataExample.dueDatesSchedule, scheduleSolution[0].length);

    return (
        <section ref={ ref } className="working-explanation-container in-view-container">
            <Title className={ `${inViewClassName} space-title` } title={ titles.explanationSection }/>

            <div className={ inViewClassNameRight }>
                <Text className="space-element-medium" text={ explanationText[0] }/>

                <div className="working-explanation-input-container space-element-medium">
                    <InputExplanation
                        className="input-single space-element-medium"
                        labelText={ inputText.group.text }
                        inputText={ inputText.group.value }
                    />

                    <InputExplanation
                        className="input-single"
                        labelText={ inputText.room.text }
                        inputText={ inputText.room.value }
                    />
                </div>

                <Text className="space-element-medium" text={ explanationText[1] }/>

                <Note className="space-element-medium" text={ notes.durationsExpression }/>

                <div className="working-explanation-data-container space-element-medium">
                    <TwoDimensionalTable className="processing-time-data-table" table={dataExample.processingTimes}/>
                    <TwoDimensionalTable className="due-time-data-table" table={dataExample.dueDates}/>                    
                </div>

                <Text className="space-element-medium" text={ explanationText[2] }/>

                <div className="working-explanation-input-container space-element-medium">
                    <InputExplanation
                        className="input-single space-element-medium"
                        labelText={ inputText.processingTimes.text }
                        inputText={ inputText.processingTimes.value }
                    />

                    <InputExplanation
                        className="input-single"
                        labelText={ inputText.dueDates.text }
                        inputText={ inputText.dueDates.value }
                    />
                </div>

                <Text className="space-element-medium" text={ explanationText[3] }/>

                <ScheduleVisualization className="space-element-medium" scheduleSolution={scheduleSolution} dueDates={dueDates} />

                <Note className="space-element-medium" text={ notes.dueDateBarDesign }/>

                <Text className="space-element-medium" text={ explanationText[4] }/>
            </div>
        </section>
    );
}

export default WorkingExplanation;