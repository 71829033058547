/**
 * Message d'erreurs pour affcihage à l'écran de l'utilisateur.
 * * `requiredField` : This field is required.
 * * `optionSelection` : Please, select an option.
 * * `invalidFormat` : This field has an invalid format.
 * * `invalidEmail` : This email has an invalid format.
 * * `invalidNonNegativeNumber` : This field must contain a non-negative number.
 * * `invalidNegativeNumber` : This field must contain a negative number.
 * * `invalidNonNegativeInteger` : This field must contain a non-negative integer.
 * * `invalidNegativeInteger` : This field must contain a negative integer.
 * * `invalidCharactersNumber` : This field exceed the maximum number of characters.
 */
export const errorMessage = {

    /** Message: This field is required. */
    requiredField: "This field is required.",

    /** Massage: Please, select an option. */
    optionSelection: "Please, select an option.",

    /** Message: This field has an invalid format. */
    invalidFormat: "This field has an invalid fomat.",

    /** Message: This field must contain a non-negative number. */
    invalidEmail: "This email has an invalid format.",

    /** Message: This field must contain a non-negative number. */
    invalidNonNegativeNumber: "This field must contain a non-negative number.",

    /** Message: This field must contain a negative number. */
    invalidNegativeNumber: "This field must contain a negative number.",

    /** Message: This field must contain a non-negative integer. */
    invalidNonNegativeInteger: "This field must contain a non-negative integer.",

    /** Message: This field must contain a negative integer. */
    invalidNegativeInteger: "This field must contain a negative integer.",

    /** Message: This field must contain a number. */
    invalidCharactersNumber: "This field exceed the maximum number of characters.",

    invalidCsvColumnsNumber: "This CSV has an invalid number of columns.",

    invalidCsvRowsNumber: "This CSV has an invalid number of rows.",

    invalidCsvNonNegativeNumber: "This CSV must contain non-negative numbers.",

    invalidCsvNegativeNumber: "This field must contain negative numbers.",
}