import { EmptyException } from "../../exception/global/empty-exception";
import { NumberException } from "../../exception/global/number-exception";
import { UndefinedException } from "../../exception/global/undefined-exception";
import { errorMessage } from "../variable/error-message";

/**
 * @brief Fonction permettant de lever les exceptions nécessaires lorsqu'un attribut est null ou requis.
 * 
 * @param value La valeur à tester.
 * @param identifier L'identifiant permettant de cibler l'objet.
 * @param attributeName Le nom de l'attribut ciblé.
 * 
 * @throws UndefinedException
 */
export const undefinedAttribute = (value: any, identifier: string, attributeName: string) : void => {
    // Si aucune valeur n'est entrée, on lève une exception
    if (value === undefined || value === null) throw new UndefinedException(identifier, attributeName);
}

/**
 * @brief Fonction permettant de lever les exceptions nécessaires lorsqu'un attribut de type string est requis et ne peut être vide.
 * 
 * @param value La valeur à tester.
 * @param identifier L'identifiant permettant de cibler l'objet.
 * @param attributeName Le nom de l'attribut ciblé. 
 * 
 * @throws UndefinedException
 * @throws EmptyException
 * 
 * @returns La valeur de l'attribut sans espaces à droite et à gauche.
 */
export const mandatoryString = (value: string, identifier: string, attributeName: string) => {
    // Si aucune valeur n'est fournie, on lève une exception.
    if (value === undefined || value === null) throw new UndefinedException(identifier, attributeName);

    // On retire les espaces à droite et à gauche de la valeur.
    value = value.trim();

    // Si la valeur entrée est vide on lève une exception.
    if (value === "") throw new EmptyException(identifier, attributeName);
}

/**
 * @brief Méthode permettant de vérifier qu'un nombre est positif.
 *
 * @param value La valeur à évaluer.
 * @param identifier L'identifiant permettant de cibler l'objet.
 * 
 * @trows NumberException
 */
export const positiveIntergerVerification = (value: number, identifier: string) => {
    if (!Number.isInteger(value) && value <= 0)
        throw new NumberException(identifier, errorMessage.invalidNonNegativeInteger);
}

/**
 * @brief Fonction permettant de lever les exceptions nécessaires lorsqu'un attribut de type tableau est requis et ne peut être vide.
 * 
 * @param value La valeur à tester.
 * @param identifier L'identifiant permettant de cibler l'objet.
 * @param attributeName Le nom de l'attribut ciblé.
 * 
 * @throws UndefinedException
 * @throws EmptyException
 * 
 * @returns La valeur de l'attribut.
 */
export const mandatoryArray = (value: any[], identifier: string, attributeName: string) : void => {
    // Si aucune valeur n'est fournie, on lève une exception.
    if (value === undefined || value === null) throw new UndefinedException(identifier, attributeName);

    // Si la valeur entrée est vide on lève une exception.
    if (value.length === 0) throw new EmptyException(identifier, attributeName);
}