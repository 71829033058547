import { IInputNumberConstructor } from "../../../interface/constructor/input/input-number-constructor";
import { errorMessage } from "../../variable/error-message";
import { regexNumber } from "../../variable/regex";
import { InputText } from "./input-text";

/**
 * Classe relative à la saisie d'un nombre.
 * 
 * @extends InputText Classe relative à la saisie d'un texte.
 */
export class InputNumber extends InputText {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputNumberConstructor
    ) {
        super(options);
        this.setRegex(regexNumber.real);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    // --------------------------
    // Setter
    // --------------------------

    // --------------------------
    // Méthode
    // --------------------------

    protected override formatErrorMessage() : string {
        let message: string = "";

        const isNonNegative: boolean = this.getSpecificPatten().includes(regexNumber.nonNegative)
            || this.getSpecificPatten().includes(regexNumber.strictlyNonNegative);

        const isNegative: boolean = this.getSpecificPatten().includes(regexNumber.negative)
            || this.getSpecificPatten().includes(regexNumber.strictlyNegative);

        if (isNonNegative) message = errorMessage.invalidNonNegativeNumber;
        else if (isNegative) message = errorMessage.invalidNegativeNumber;

        return message;
    }
}