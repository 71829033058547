import React from "react";
import { IErrorRef, IInputRef, generateExample, groupsOnBlur, initInputs, roomsOnBlur, schedule } from "../../../controller/schedule/schedule-form";
import { InputTextComponent, InputTextareaComponent } from "../../component/input/input-component";
import { scheduleFormToastBoxInfoMessage } from "../../../text-resources/schedule-page/taost-box-message";
import { SectionTitle }from "src/content/view/component/text/title";
import { useInView } from "react-intersection-observer";
import SecondaryButton from "../../component/button/secondary-button";
import { IScheduleForm } from "../../../interface/input/schedule-form";
import ScheduleVisualization from "../../component/schedule-visualization";
import { IScheduleSolution } from "../../../interface/adapter/api-to-client/schedule-solution";
import { dueDateFormatArray, getLmax, scheduleArrayFormat } from "../../../controller/global/schedule-visualization";
import Loader from "../../component/loader/square-loader";
import Text from "../../component/text/text";
import Note from "../../component/card/note";

interface IScheduleButtonProps {
    inputs: IScheduleForm,
    errorRef: IErrorRef
}

const ScheduleButton = (props: IScheduleButtonProps) => {
    const [loading, setLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
         = React.useState<boolean>(false);

    const [showSchedule, setShowSchedule]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
        = React.useState<boolean>(false);

    const [res, setRes] : [IScheduleSolution | null, React.Dispatch<React.SetStateAction<IScheduleSolution | null>>]
        = React.useState<IScheduleSolution | null>(null);

    return (
        <>
            {
                loading
                    ? <div className="schedule-form-button-loader">
                        <Loader className="space-element-medium"/>
                        <Text text="It might take some time ..."/>
                    </div>
                    : <SecondaryButton type='submit' className="button button-fill-secondary space-element-medium" onClick={() => {
                        schedule({
                            nbOfGroups: props.inputs.nbOfGroups,
                            nbOfRooms: props.inputs.nbOfRooms,
                            processingTimes: props.inputs.processingTimes,
                            dueDates: props.inputs.dueDates
                        }, {
                            nbOfGroupsRef: props.errorRef.nbOfGroupsRef,
                            nbOfRoomsRef: props.errorRef.nbOfRoomsRef,
                            processingTimesRef: props.errorRef.processingTimesRef,
                            dueDatesRef: props.errorRef.dueDatesRef
                        }, 
                        setLoading, setShowSchedule, setRes)
                    }}>
                        Schedule!
                    </SecondaryButton>

                    
            }
            
            {
                !showSchedule || loading
                    ? <></> 
                    : <div>
                        <Note 
                            className="space-element-medium"
                            text="By clicking on the Schedule button again, do not be surprised to obtain a different solution than the previous one."
                        />
                        <Text className="text-bold space-element-medium" text={"Total Time : " + getLmax(res!.dueDateTimeRange) + " minutes."}/>
                        <ScheduleVisualization
                            scheduleSolution={scheduleArrayFormat(res!.solution)}
                            dueDates={dueDateFormatArray(res!.dueDateTimeRange, scheduleArrayFormat(res!.solution)[0].length)}
                            className="space-element-extra-large"
                        />
                    </div>
            }
        </>
    )
    
}

const ScheduleForm: React.FC = () : React.JSX.Element => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    const titleClassName: string = `schedule-form-title space-title ${inViewClassName}`;
    const formClassName: string = `schedule-form ${inViewClassName}`;
    const className: string = "space-element-medium";

    const inputs: IScheduleForm = initInputs();
    const errorRef: IErrorRef = {
        nbOfGroupsRef: React.useRef(),
        nbOfRoomsRef: React.useRef(),
        processingTimesRef: React.useRef(),
        dueDatesRef: React.useRef()
    };

    const inputRef: IInputRef = {
        nbOfGroupsRef: React.useRef(),
        nbOfRoomsRef: React.useRef(),
        processingTimesRef: React.useRef(),
        dueDatesRef: React.useRef()
    }

    return (
        <section ref={ref} className="schedule-form-container in-view-container space-element-extra-large">
            <SectionTitle className={titleClassName} title = "Enter the information regarding museum visits" />
            <SecondaryButton
                className="button button-fill-secondary" 
                onClick={() => {
                    generateExample(inputs, inputRef)
                }}>
                Generate example
            </SecondaryButton>
            <form className={formClassName} onSubmit={(e) => e.preventDefault()}>
                 <InputTextComponent 
                    classNameInputBase={className}
                    input={inputs.nbOfGroups}
                    displayInputConstraints={true}
                    errorRef={errorRef.nbOfGroupsRef}
                    inputContraintsExplanationMessage={scheduleFormToastBoxInfoMessage.nbOfVisitorGroups}
                    onBlur={() => groupsOnBlur(inputs)}
                    placeholder="Example: 3"
                    inputRef={inputRef.nbOfGroupsRef}
                />

                <InputTextComponent 
                    classNameInputBase={className}
                    input={inputs.nbOfRooms} 
                    displayInputConstraints={true}
                    errorRef={errorRef.nbOfRoomsRef}
                    inputContraintsExplanationMessage={scheduleFormToastBoxInfoMessage.nbOfRooms}
                    onBlur={() => roomsOnBlur(inputs)}
                    placeholder="Example: 4"
                    inputRef={inputRef.nbOfRoomsRef}
                />

                <InputTextareaComponent 
                    classNameInputBase={className}
                    input={inputs.processingTimes}
                    displayInputConstraints = {true}
                    errorRef={errorRef.processingTimesRef}
                    inputContraintsExplanationMessage = {scheduleFormToastBoxInfoMessage.processingTimes}
                    rows={5}
                    placeholder={"Example: \n25 ; 15 ; 30 ; 20 \n23 ; 10 ; 18 ; 18 \n17 ; 22 ; 31 ; 10"}
                    inputRef={inputRef.processingTimesRef}
                />

                <InputTextareaComponent 
                    classNameInputBase={className}
                    input={inputs.dueDates} 
                    displayInputConstraints={true}
                    errorRef={errorRef.dueDatesRef}
                    inputContraintsExplanationMessage={scheduleFormToastBoxInfoMessage.dueDates}
                    rows={1}
                    placeholder={"Example: 5 ; 3 ; 2"}
                    inputRef={inputRef.dueDatesRef}
                />

                <ScheduleButton inputs={inputs} errorRef={errorRef} />
            </form>
        </section>
    );
}

export default ScheduleForm;