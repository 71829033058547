import { Title }from "../../component/text/title";
import niceToMeetYou from '../../../../assets/image/nice-to-meet-you.svg';
import TwoCellsGrid from "../../component/container/two-cells-grid";
import Text from "../../component/text/text";
import { aboutHeader } from "../../../text-resources/about-page/about-header";
import { useInView } from "react-intersection-observer";
import { InViewMotionCellProps } from "../../../interface/props/container/two-cells-grid-props";
    
const LeftCellContent: React.FC<InViewMotionCellProps> = (props: InViewMotionCellProps) => {
    const inViewClassName: string = (props.inView) 
        ? 'in-view-motion-show in-view-motion-to-top-or-bottom-show' 
        : 'in-view-motion-hidden in-view-motion-to-bottom-hidden'

    const textClassName: string = `header-text ${(inViewClassName)}`;

    return (
        <>
            <Title
                className="header-title"
                title={ aboutHeader.title }
            />
            <hr className="header-rule"/>
            <Text 
                className={textClassName}
                text={ aboutHeader.text }
            />
        </>
    );
}

const RightCellContent = () => {
    return (
        <img 
            src={ niceToMeetYou }
            className="header-image" 
            alt="About header showing two persons cheering." 
        />
    );
}

const AboutHeader = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden'

    const rightCellClassName: string = `header-image-container ${inViewClassName}`;
    const leftCellClassName: string = `${(inViewClassName)}`;

    return (
        <header className="about-header-container in-view-container space-element-extra-large">
            <TwoCellsGrid
                twoCellsGridRef={ ref }
                rightCellClassName={ rightCellClassName }
                leftCellClassName={ leftCellClassName }
                leftCellContent = { <LeftCellContent inView={inView}/> }
                rightCellContent = { <RightCellContent/> }
            />
        </header>
    );
}

export default AboutHeader;