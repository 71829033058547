import { TwoCellsGridProps } from "../../../interface/props/container/two-cells-grid-props";

/**
 * @brief Composant permettant d'afficher une grille à deux cellules.
 * @param props TwoCellsGridProps : Interface permettant de caractériser une grille composé d'uniquement deux cellules
 * @returns Composant permettant d'afficher une grille à deux cellules.
 * 
 * @prop {React.ReactNode} leftCellContent : Contenu de la cellule de gauche.
 * @prop {React.ReactNode} rightCellContent : Contenu de la cellule de droite.
 * @prop {string} parentClassName : Classe CSS du conteneur de la grille.
 * @prop {string} leftCellClassName : Classe CSS du conteneur de la cellule de gauche.
 * @prop {string} rightCellClassName : Classe CSS du conteneur de la cellule de droite.
 * @prop {any} twoCellsGridRef : Référence vers le conteneur de la grille.
 */
const TwoCellsGrid: React.FC<TwoCellsGridProps> = (props: TwoCellsGridProps): JSX.Element => {
    const parentClassName: string = `two-cells-grid-container ${props.parentClassName}`;
    const leftCellClassName: string = `left-cell-container ${props.leftCellClassName}`;
    const rightCellClassName: string = `right-cell-container ${props.rightCellClassName}`;

    return (
        <div ref={props.twoCellsGridRef} className={parentClassName}>
            <div className={leftCellClassName}>
                {props.leftCellContent}
            </div>
            <div className={rightCellClassName}>
                {props.rightCellContent}
            </div>      
        </div>
    );
}

export default TwoCellsGrid;