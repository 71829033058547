import Footer from "../element/footer";
import HomeCards from "../element/home/home-cards";
import HomeGetStarted from "../element/home/home-get-started";
import HomeHeader from "../element/home/home-header";
import HomeScheduleExplanation from "../element/home/home-schedule-explanation";

const HomeScreen = () => {
    return (
        <>
            <HomeHeader/>
            <HomeScheduleExplanation/>
            <HomeCards/>
            <HomeGetStarted/>
            <Footer/>
        </>
        
    );
}

export default HomeScreen;