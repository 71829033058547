import { useInView } from "react-intersection-observer";
import { Title }from "../../component/text/title";
import { aboutTeamPresData, aboutTeamPresTitle } from "../../../text-resources/about-page/about-team-pres";
import Text from "../../component/text/text";
import aiStudent from "../../../../assets/image/ai-student.svg";


const AboutTeamPresentation = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    return (
        <div ref={ref} className="in-view-container space-element-extra-large about-team-pres-container">
            <Title className={"space-title " + inViewClassName} title={ aboutTeamPresTitle } />
            <Text text={ aboutTeamPresData } className={ inViewClassName + " space-element-medium" }/>
            <img
                src = { aiStudent }
                alt = "Student with AI"
                className={ "about-team-pres-image" }
            />                
        </div>   
    );
}

export default AboutTeamPresentation;