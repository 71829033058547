import { CardProps } from "../../interface/props/card/card-props";
import illustration from '../../../assets/image/illustration.svg';
import code from '../../../assets/image/code.svg';

export const contactCardsEn: CardProps[] = [
    {
        iconPath: code,
        text: "If you want to talk about artificial intelligence, optimization, or web developpement project.",
        title: "contact.museum-scheduling@proton.me",  
        containerClassName: "grid-cell-0-0"
    },
    {
        iconPath: illustration,
        text: "If you want to know more about this website illustrations.",
        title: "https://storyset.com/contact",
        containerClassName: "grid-cell-0-1"
    },
];