import React from "react";
import { InputText } from "../../model/object/input/input-text";

/**
 * Interface permettant de caractériser le contexte relatif à un input et composée de :
 * * `input` *(InputText)* : L'objet de saisie de texte à associer au contexte.
 * * `setError` *(React.Dispatch<React.SetStateAction<string>>)* : La fonction permettant de modifier le message d'erreur.
 */
export interface InputContextProps {
    /** L'objet de saisie de texte à associer au contexte. */
    input: InputText;

    /** La fonction permettant de modifier le message d'erreur. */
    setError: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * @brief Contexte permettant de partager l'objet de saisie de texte et la fonction permettant de modifier le message d'erreur.
 * 
 * @prop {InputText} input L'objet de saisie de texte à associer au contexte.
 * @prop {React.Dispatch<React.SetStateAction<string>>} setError La fonction permettant de modifier le message d'erreur.
 */
export const InputContext = React.createContext<InputContextProps | null>(null);