import { IInputNumberConstructor } from "../../../interface/constructor/input/input-number-constructor";
import { regexNumber } from "../../variable/regex";
import { InputNumber } from "./input-number";

/**
 * Classe relative à la saisie d'un nombre entier.
 * 
 * @extends InputNumber Classe relative à la saisie d'un nombre.
 */
export class InputInteger extends InputNumber {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputNumberConstructor
    ) {
        super(options);
        this.setRegex(regexNumber.integer);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    // --------------------------
    // Setter
    // --------------------------

    // --------------------------
    // Méthode
    // --------------------------
}