import { TwoDimensionalTableProps } from "../../../interface/props/table/two-dimensional-table-props";
import Text from "../text/text";

/**
 * @brief Composant permettant d'afficher un tableau à deux dimensions.
 * @param props TwoDimensionalTableProps : Interface permettant de caractériser un tableau à deux dimensions.
 * @returns Composant permettant d'afficher un tableau à deux dimensions.
 * 
 * @prop {string} className : La classe CSS appliquée au tableau à afficher.
 * @prop {Array<Array<string>>} table : Le tableau qu'il faut rendre.
 */
const TwoDimensionalTable: React.FC<TwoDimensionalTableProps> = (props: TwoDimensionalTableProps) => {
    const className: string = `grid-table-container ${props.className}`;

    return (
        <div className={className}>
            {
                props.table.map((_) => {
                    return _.map((cell: string, cellCount: number) => {
                        return (
                            <div key={cellCount} className="grid-cell">
                                <Text text={cell}/>
                            </div>
                        )
                    });
                })
            }
        </div>
    );
};

export default TwoDimensionalTable;
