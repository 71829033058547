import { CardProps } from '../../../interface/props/card/card-props';
import Text from '../text/text';

/**
 * @brief Composant de carte permettant l'affichage d'une icone, d'un titre et d'un texte.
 * @param props CardProps : Interface permettant de caractériser un bouton
 * @returns Composant de carte permettant la mise en évidence de certaines données.
 * 
 * @prop {string} containerClassName : Classe CSS du conteneur de la carte.
 * @prop {string} iconClassName : Classe CSS du conteneur de l'icone.
 * @prop {string} titleClassName : Classe CSS du titre.
 * @prop {string} textClassName : Classe CSS du texte.
 * @prop {any} cardRef : Référence vers la carte.
 * @prop {string} iconPath : Chemin vers l'icone.
 * @prop {string} title : Titre de la carte.
 * @prop {string} text : Texte de la carte.
 */
const Card: React.FC<CardProps> = (props: CardProps): JSX.Element => {
    const classNameContainer: string = `card-container ${props.containerClassName}`;
    const iconClassName: string = `icon-container ${props.iconClassName}`;
    const titleClassName: string = `text-bold text-primary card-title ${props.titleClassName}`;
    const textClassName: string = `${props.textClassName}`;

    return (
        <div ref={props.cardRef} className={classNameContainer}>
            <div className={iconClassName}>
                <img src={props.iconPath} alt="icon-card" className="icon-img"/>
            </div>
            <Text
                text={props.title}
                className={titleClassName}
            />
            <Text
                className={textClassName}
                text={props.text}
            />
            <span className="border"></span>            
        </div>
    );
}

export default Card;