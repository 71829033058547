import { errorToastBox } from "../../../controller/global/toast-box";
import { IScheduleSolution } from "../../../interface/adapter/api-to-client/schedule-solution";
import { IOpenShopScheduling } from "../../../interface/adapter/client-to-api/open-shop-scheduling";
import { IScheduleForm } from "../../../interface/input/schedule-form";
import { ScheduleParamToApi } from "../../../model/object/adapter/client-to-api/open-shop-scheduling";
import { BASE_URL_OPEN_SHOP } from "../../config/config";
import { postInitRequest } from "../../header/header";

export const post = async (exercise: IScheduleForm) : Promise<IScheduleSolution | null> => {
    let result: IScheduleSolution | null = null;

    try { 
        // On adapte l'objet de type IScheduleForm en IOpenShopScheduling, donc on adapte les données du front pour l'envoi à l'API.
        const postData: IOpenShopScheduling = ScheduleParamToApi.getInstance().adapt(exercise);

        // On envoie la requête à l'API.
        const response: Response = await fetch(BASE_URL_OPEN_SHOP.openShop, postInitRequest(postData))

        // On récupère la réponse de l'API, ici, il s'agit de la solution d'odonnancement selon les données front envoyées.
        if (response.ok) result = await response.json();  
    } catch (e: any) {
        errorToastBox("Ups, something went wrong. Please try again later.");
    }

    return result;
}