export const scheduleFormToastBoxInfoMessage = {
    nbOfVisitorGroups: "This field is required and can only contains non-negative integer standing for the number of visitor groups for a given day.",

    nbOfRooms: "This field is required and can only contains non-negative integer standing for the number of rooms to visit for a given day.",

    processingTimes: "This field is required and can only contains non-negative integer standing for times spend in each room for each visitor group. \n\nThe number of rows corresponds to the number of visitor groups for a given day. \n\nThe number of columns corresponds to the number of rooms to visit for a given day. \n\nFor instance, a correct CSV formatting for 2 groups and 5 rooms, should be as follows: : \n\n \t 2 ; 7 ; 6 ; 9 ; 5 \n \t 3 ; 1 ; 4 ; 8 ; 0",

    dueDates: "The number of columns corresponds to the number of groups for a given day. \n\nFor instance, a correct CSV formatting for 5 groups should be as follows: : \n\n \t 2 ; 7 ; 6 ; 9 ; 5 \n\nIf you do not want to specify any times to start point for each visitor group, set these durations to \n\n \t 0 ; 0 ; 0 ; 0 ; 0.",
}

export const scheduleFormNbOfRoomsAndGroupsToastBoxErrorMessage: string = "Check that the number of visitor groups has been entered and that the provided value is a positive integer.";

export const scheduleFormProcessingTimesToastBoxErrorMessage = (rows: string, cols: string) : string => {
    return `Check that there are indeed ${rows} rows (visitor groups) and ${cols} columns (rooms to visit).\n\nCheck that the CSV formatting is correct. For instance, with 2 groups and 5 rooms, the formatting should be as follows: : \n\n \t 2 ; 7 ; 6 ; 9 ; 5 \n \t 3 ; 1 ; 4 ; 8 ; 0`;
}

export const scheduleFormDueDatesToastBoxErrorMessage = (cols: string) : string => {
    return `Check that there are indeed ${cols} columns (visitor groups).\n\nCheck that the CSV formatting is correct. For instance, with 5 groups, the formatting should be as follows: : \n\n \t 2 ; 7 ; 6 ; 9 ; 5`;
}