/**
 * @brief Fonction permettant d'initialiser les mouvements de slide de chaque élements de la page courante.
 * @param setIsLoading Gestion du chargement de la page.
 * @return Fonction permettant de supprimer l'écouteur d'évènement.
 */
export const initSlideMotion = (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const handleScrollToTop = () => {
        // Il y a le chargement de la page.
        setIsLoading(true);

        // On remonte en haut de la page.
        window.scrollTo(0, 0);

        // On attend 300ms avant de supprimer le chargement de la page, ce qui permet de rendre le scroll jusqu'en haut de la page non visible à l'utilisateur.
        setTimeout(() => setIsLoading(false), 300); 
    };

    // On ajoute un écouteur d'évènement sur le bouton de retour en arrière du navigateur.
    window.addEventListener('popstate', handleScrollToTop);
    handleScrollToTop();

    // On retourne une fonction permettant de supprimer l'écouteur d'évènement.
    return () => window.removeEventListener('popstate', handleScrollToTop);
}