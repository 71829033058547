import { FormatException } from "../../../exception/global/format-exception";
import { CharacterNumberException } from "../../../exception/input/character-number-exception";
import { RequiredFieldException } from "../../../exception/input/required-field";
import { IInputTextConstructor } from "../../../interface/constructor/input/input-text-constructor";
import { concatRegex } from "../../tool/regex-manipulation";
import { positiveIntergerVerification } from "../../tool/setter";
import { errorMessage } from "../../variable/error-message";
import { regexBasis } from "../../variable/regex";
import { Input } from "./input";

/**
 * Classe relative à la saisie d'un texte.
 * 
 * @property _regex *(RegExp)* : Une expression régulière permettant de valider la saisie de l'utilisateur.
 * @property _specificPatten *(RegExp[])* : Un tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur.
 * @property _nbOfCharacters *(number)* : Le nombre de caractères maximum que peut contenir la zone de texte.
 * 
 * @extends Input Classe abstraite relative aux diverses caractéristiques de base que peut comporter un input.
 */
export class InputText extends Input<string> {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    /** Une expression régulière permettant de valider la saisie de l'utilisateur. */
    protected _regex!: RegExp;

    /** Un tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur. */
    protected _specificPatten!: RegExp[];

    /** Le nombre de caractères maximum que peut contenir la zone de texte. */
    protected _nbOfCharacters!: number;

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputTextConstructor
    ) {
        super(options);
        this.setRegex(options.regex!);
        this.setSpecificPatten(options.specificPatten!);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    /**
     * @brief Méthode permettant de récupérer l'expression régulière permettant de valider la saisie de l'utilisateur.
     * @returns L'expression régulière permettant de valider la saisie de l'utilisateur.
     */
    public getRegex() : RegExp 
    { return this._regex; }

    /**
     * @brief Méthode permettant de récupérer le tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur.
     * @returns Le tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur.
     */
    public getSpecificPatten() : RegExp[]
    { return this._specificPatten; }

     /**
     * @brief Récupère le nombre de caractères maximum que peut contenir la zone de texte.
     * @return Le nombre de caractères maximum que peut contenir la zone de texte.
     */
     public getNbOfCharacters() : number 
     { return this._nbOfCharacters; }

    // --------------------------
    // Setter
    // --------------------------

    public override setValue(value: string = "") : void {
        super.setValue(value);
        this.setError("");
    }

    public override setError(error: string): void {
        super.setError(error);

        // Si le champ n'est pas requis, et qu'il est vide, on initalise l'erreur comme étant vide.
        if (this.isEmptyAndNotRequired()) this._error = "";

        // Si le champ est requis mais que la saisie est vide, on récupère l'erreur correspondante.
        else if (this.isRequiredAndEmpty()) this._error = errorMessage.requiredField;

        // Si le champ n'est pas valide, dans le sens où le pattern ne corespond pas, on récupère l'erreur correspondante.
        else if (!this.isFormatValid()) this._error = this.formatErrorMessage();

        // Si le nombre de caractères saisis est supérieur au nombre de caractères maximum que peut contenir la zone de texte, on récupère l'erreur correspondante.
        else if (this.getNbOfCharacters() < this.countCharacters()) this._error = errorMessage.invalidCharactersNumber;

        // Si tout va bien, on initialise l'erreur comme étant vide.
        else this._error = "";
    }

    /**
     * @brief Méthode permettant de définir l'expression régulière permettant de valider la saisie de l'utilisateur.
     * @param regex L'expression régulière permettant de valider la saisie de l'utilisateur.
     */
    public setRegex(regex: RegExp = regexBasis.default) : void {
        this._regex = regex;
    }

    /**
     * @brief Méthode permettant de définir le tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur.
     * @param specificPatten Le tableau d'expressions régulières spécifiques permettant de valider la saisie de l'utilisateur.
     */
    public setSpecificPatten(specificPatten: RegExp[] = []) : void {
        this._specificPatten = specificPatten;
    }

    /**
     * @brief Définit le nombre de caractères maximum que peut contenir la zone de texte.
     * @param nbOfCharacters Le nombre de caractères maximum que peut contenir la zone de texte.
     */
    public setNbOfCharacters(nbOfCharacters: number) : void {
        // Si la valeur fournie n'est pas un entier strictement positif, on lève une exception 
        if (nbOfCharacters !== null && nbOfCharacters !== undefined) {
            positiveIntergerVerification(nbOfCharacters, this.getKey());
            
            this._nbOfCharacters = nbOfCharacters;
        } 
        
        this._nbOfCharacters = 7000;
    }

    // --------------------------
    // Méthode
    // --------------------------

    /**
     * @brief Méthode permettant de déterminer si le champ est vide alors qu'il est requis.
     * 
     * @returns Un booléen déterminant si le champ est vide alors qu'il est requis.
     */
    public isRequiredAndEmpty() : boolean {
        return this.getIsRequired() && this.getValue() === "";
    }

    /**
     * @brief Méthode permettant de déterminer si le champ est vide et qu'il n'est pas requis.
     * 
     * @returns Un booléen déterminant si le champ est vide et qu'il n'est pas requis.
     */
    public isEmptyAndNotRequired() : boolean {
        return !(this.getIsRequired()) && this.getValue() === "";
    }

    /**
     * @brief Méthode permettant de déterminer si la saisie de l'utilisateur est valide.
     * @returns Un booléen déterminant si la saisie de l'utilisateur est valide.
     */
    public isFormatValid() : boolean {
        let specificPattern: RegExp = concatRegex(this.getSpecificPatten());

        return this.getRegex().test(this.getValue()) 
            && specificPattern.test(this.getValue());
    }

    /**
     * @brief Méthode permettant de récupérer le message d'erreur associé à un champ au formattage invalide.
     * @returns Le message d'erreur associé à un champ au formattage invalide.
     */
    protected formatErrorMessage() : string {
        return errorMessage.invalidFormat;
    }

    /**
     * @brief Méthode peremttant de compter le nombre total de caractères saisis dans la zone de texte.
     * @return Le nombre total de caractères saisis dans la zone de texte.
     */
    public countCharacters() : number {
        const tabValue: string[] = this.getValue().split('\n');
        let nbOfCharacters: number = 0;

        for (let line of tabValue) nbOfCharacters += line.trim().length;
        
        return nbOfCharacters;
    }

    public override validator() : void {
        super.validator();

        // On retire les espaces à droite et à gauche de la saisie.
        this.setValue(this.getValue().trim());

        // Si la valeur est requise mais vide, on lève une exception.
        if (this.isRequiredAndEmpty()) throw new RequiredFieldException(this.getKey());

        // S'il y plus de caractères comptés que le nombre de caractères maximum, on lève une exception.
        if (this._nbOfCharacters < this.countCharacters()) throw new CharacterNumberException(this.getKey(), this._nbOfCharacters);

        // Si le champ n'est pas valide, dans le sens où le pattern ne corespond pas, on lève une exception.
        if (!this.isFormatValid()) throw new FormatException(this.getKey(), errorMessage.invalidFormat);
    }
}