import React from "react";
import { InputText } from "../../../../model/object/input/input-text";
import { InputContext, InputContextProps } from "../../../context/input-context";
import { InputTextTypeProps } from "../../../../interface/props/input/input-text-type-props";
import { onChangeInputTextType } from "../../../../controller/schedule/schedule-inputs";

/**
 * @brief Composant permettant la saisie d'un texte, nombre, email, ...
 * @param props InputTextTypeProps : Interface permettant de caractériser un input permettant une saisie de texte, mail, nombre, ...
 * @returns Composant permettant la saisie d'un texte, nombre, email, ...
 * 
 * @prop {boolean} readonly : Détermine si oui ou non l'input est en lecteur seul.
 * @prop {boolean} disabled : Détermine si oui ou non l'input est désactivé.
 * @prop {boolean} autofocus : Détermine si oui ou non l'input est en autofocus.
 * @prop {string} value : La valeur de l'input.
 * @prop {string} placeholder : Le placeholder de l'input.
 * @prop {string} className : La classe de l'input.
 * @prop {React.FocusEventHandler | undefined} onBlur : La fonction permettant de gérer un évènement lorsque le focus y est perdu.
 */
const InputTextType: React.FC<InputTextTypeProps> = (props: InputTextTypeProps): JSX.Element => {
    // Utilisation du contexte.
    const context: InputContextProps = React.useContext(InputContext)!;
    const input: InputText = context.input;
    const setError: React.Dispatch<React.SetStateAction<string>> = context.setError;

    return (
        <div className="input-text-container">
            <input 
                ref={props.inputRef}
                type="text" 
                id={input.getKey()}
                name={input.getKey()}
                className={"input-text " + props.className}
                readOnly={props.readonly}
                disabled={props.disabled}
                autoFocus={props.autofocus}
                value={props.value}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
                onChange={(e: any) => onChangeInputTextType(e, input, setError)}/>
        </div>
    );
};

export default InputTextType;