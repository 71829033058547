/**
 * Classe d'exception permettant de retourner un message stipulant que le nombre de lignes ou de colonnes dans un csv n'est pas correcte.
 * 
 * @extends Error Classe parente d'exception.
 */
export class CsvSizeException extends Error {

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(keyName: string, type: string, expectedNb: number) {
        super(`${ keyName } : The number of ${type} is not correct. Expected ${expectedNb} ${type}.`);
        Object.setPrototypeOf(this, CsvSizeException.prototype);
    }
}