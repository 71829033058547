import ContactCards from "../element/contact/contact-cards";
import ContactHeader from "../element/contact/contact-header";
import Footer from "../element/footer";

const ContactScreen = () => {
    return (
        <>
            <ContactHeader/>
            <ContactCards/>
            <Footer/>
        </>
    );
}

export default ContactScreen;