import genetic from "../../../assets/image/genetic.svg";
import schedule from "../../../assets/image/schedule.svg";
import museumApplication from "../../../assets/image/museum-application.svg";
import optimization from "../../../assets/image/optimization.svg";

export const aboutProblemExplanationTitle: string = "Why does this website exist ?";

export interface IAboutProblemExplanation {
    image: string;
    text: string;
    id: string;
    className: string;
    position?: number;
}

export const aboutPageExplanationData: IAboutProblemExplanation[] = [
    {
        image: schedule,
        text: "Passionate about optimization and artificial intelligence, my interest quickly turned to solving the complex problem of Open Shop with delivery times. The Open Shop is a scheduling problem where a set of tasks needs to be executed on multiple machines, each having its own sequence of operations to perform. A primary constraint is that operations from the same task cannot overlap. Furthermore, the additional constraint of delivery times adds an extra dimension, as it is necessary to consider a delivery time after the completion of each task's last executed operation. This makes the problem even more challenging.",
        id: "schedule",
        className: "about-problem-explanation-schedule",
        position: 0
    },
    {
        image: optimization,
        text: "The Open Shop scheduling problem with delivery times is classified as an NP-hard problem. This means that, for tasks involving more than 3 operations, with variable processing times, it is impossible to find an optimal solution in a reasonable amount of time (The time is counted in terms of hundreds, thousands, or even millions of years for certain problems). Faced with this complexity, it became crucial to find an efficient approach to obtain results that are as close to optimal as possible within reasonable time frames. This is when genetic algorithms came into play.",
        id: "optimization",
        className: "about-problem-explanation-optimization",
        position: 1
    },
    {
        image: genetic,
        text: "Genetic algorithms are optimization techniques inspired by the theory of natural evolution. They are designed to explore vast solution spaces and converge towards high-quality solutions. In the case of Open Shop with delivery times, the genetic algorithm has allowed the creation of a population of potential solutions in the form of ordered sequences of tasks for each workstation. The optimization process then involved applying genetic operators such as selection, crossover, and mutation to enhance the quality of solutions over generations. The iterative process of the genetic algorithm continued until a satisfactory or even optimal solution was found, taking into consideration the delivery time constraint for each task.",
        id: "genetic",
        className: "about-problem-explanation-genetic",
        position: 0
    },
    {
        image: museumApplication,
        text: "Thanks to this genetic algorithm-based approach, I was able to achieve promising results for the Open Shop problem with delivery times. This experience deeply intrigued me, and consequently, I wanted to apply my algorithm. The applications of the Open Shop problem with delivery times are numerous and diverse, spanning various fields of activity, such as manufacturing, logistics, healthcare, and also a very relevant and interesting case of utilizing this complex scheduling lies in the domain of managing visitor groups for museums. This enables smoother access to different exhibition areas.",
        id: "museum-application",
        className: "about-problem-explanation-museum-application",
        position: 1
    }
]