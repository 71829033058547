import { Chart, ChartOptions, LegendElement, LegendItem } from "chart.js";
import { generateRandomColor, initTriDimensionamlArray, transpose } from "../../tool/utils";
import { uniformColorSet } from "../../variable/constant";
import { ScheduleVisualizationProps } from "../../interface/props/schedule-visualization";

/**
 * Plugin permettant d'augmenter l'espace entre la légende et le Chart.
 */
export const chartJsPlugin = {
    id: "increase-legend-spacing",

    beforeInit(chart: any) {
        // On récupère la référence de la fonction fit d'origine.
        const originalFit = chart.legend.fit;

        // On surcharge la fonction fit() afin d'y modifier l'espace situé entre la légende et le Chart.
        chart.legend.fit = function fit() {
            // Appelle la fonction d'origine et lie le scope afin d'utiliser `this` correctement à l'intérieur.
            originalFit.bind(chart.legend)();

            // Changement de la hauteur comme souhaité.
            this.height += 20;
        }
    }
}; 

/**
 * @brief Fonction permettant de générer un dataset pour le Chart Bar selon la solution d'ordonnancement fournie.
 * @param props Les paramètres pour la visualisation.
 * @returns Un dataset pour le Chart Bar.
 */
export const generateDatasetForChart = (props: ScheduleVisualizationProps) : any => {
    // On génère un tableau de couleurs aléatoires pour la représentation des groupes.
    const colors: string[] = (props.scheduleSolution.length <= uniformColorSet.length) 
        ? uniformColorSet
        : props.scheduleSolution.map(() => generateRandomColor())

    return {
        labels: Array.from(Array(props.scheduleSolution[0].length), (_, i) => `R${i + 1}`),
        datasets: props.dueDates.map((machine) => ({ // On génère un dataset pour la représentation des latences.
            // On ne veut pas de tooltips pour les latences.
            label: `No Tooltips here`,
            // On traite les latences comme des barres empilées.
            data: machine.map((job: number[]) => ((job[1] - job[0]) === 0) ? 0 : [job[0], job[1]]),
            // Choix de la couleur des latences.
            backgroundColor: machine.map(() => "#e37475"),
            // Choix de la couleur de la bordure des latences.
            borderColor: machine.map(() => "black"),
            // Choix de la largeur de la bordure des latences.
            borderWidth: 1,
            // On impose une bordure pour les latences.
            borderSkipped: false,
            // Choix de l'épaisseur des latences.
            barThickness: 10,
            // Choix du type de graphique pour les latences, ici des barres.
            type: 'bar'
        })).concat(props.scheduleSolution.map((machine, j) => ({ // On génère un dataset pour la représentation des groupes.
            // Choix de l'affichage des tooltips pour les groupes.
            label: `Group ${j + 1}`,
            // On traite les groupes comme des barres empilées.
            data: machine.map((job: number[]) => ((job[1] - job[0]) === 0) ? 0 : [job[0], job[1]]),
            // Choix de la couleur des groupes.
            backgroundColor: machine.map((job: number[]) => colors[job[2]]),
            // Choix de la couleur de la bordure des groupes.
            borderColor: machine.map(() => "black"),
            // Choix de la largeur de la bordure des groupes.
            borderWidth: 1,
            // On impose une bordure pour les groupes.
            borderSkipped: false,
            // Choix de l'épaisseur des groupes.
            barThickness: 30,
            // Choix du type de graphique pour les groupes, ici des barres.
            type: 'bar'
        })))
    };
}

/**
 * @brief Fonction permettant de générer les actions au click des labels de la légende du Chart.
 * @param context Le contexte du Chart.
 * @param legendItem L'item de la légende sur lequel on a cliqué.
 * @param legend La légende du Chart.
 */
const onClickChartLegendItem = (context: any, legendItem: LegendItem, legend: LegendElement<"bar">) : void => {
    // Récupération de l'indice courant corespondant au data set.
    const index = legendItem.datasetIndex!;

    // Récupération du data set courant.
    const currentDataset = context!.chart.data.datasets[index];

    // Récupération du chart.
    let chart = legend.chart;

    // Mise à jour de la bordure du data set courant.
    currentDataset.borderWidth = currentDataset.borderWidth === 1 ? 5 : 1;

    // Mise à jour de la couleur de la bordure du data set courant.
    currentDataset.borderColor = currentDataset.borderColor[0] === "black" 
        ? currentDataset.borderColor.fill("#ff4760") 
        : currentDataset.borderColor.fill("black");

    // Mise à jour de la bordure du chart.
    chart.update();
}

/**
 * @brief Fonction permettant de générer les options pour le Chart Bar.
 * @param maxDataValue La valeur maximale des données du Chart.
 * @returns Les options pour le Chart Bar.
 */
export const generateOptionsForHorizontalChartBar = (scheduleSolution: number[][][]): ChartOptions<'bar'> => {
    const maxDataValue = getMaxValueFromScheduleSoluition(scheduleSolution);

    return {
        scales: {
            x: {
                // On remet chacun des datasets à la même hauteur (on veut éviter qu'ils s'ajoutent et faussent l'affichage des groupes dans les salles).
                beginAtZero: true,

                // On customise les labels de l'axe des abscisses.
                ticks: {
                    // On affiche trois labels uniquement.
                    maxTicksLimit: maxDataValue + 1,
                    callback: (v) => v,
                },

                // On retire la grille de l'axe des abscisses.
                grid: { display: false },
            },

            // Sur l'axe des ordonnées, on veut que les barres s'affichent les unes au-dessus des autres.
            y: { 
                stacked: true,

                // On retire la grille de l'axe des ordonnées.
                grid: { display: false },
            },
        },

        interaction: { intersect: true },

        // Permet d'avoir un graph avec des barres horizontales.
        indexAxis: 'y',

        // On garde le responsive du Chart.
        responsive: true,

        // On retire le ratio de l'aspect du Chart pour les barres car on veut pouvoir customiser la hauteur du Chart.
        maintainAspectRatio: false,

        plugins: {
            tooltip: {
                // On affiche les tooltips uniquement pour les groupes pas pour les latences.
                filter: (tooltipItem) => tooltipItem.dataset.label !== "No Tooltips here"
            },
            legend: {
                onClick: onClickChartLegendItem,
                // Affichage d'un pointeur au hover de la legend pour signifier que l'on peut cliquer sur les labels.
                onHover: (e) => { if (e.native!.target! instanceof HTMLElement) e.native!.target!.style.cursor = 'pointer'; },
                // Affichage du curseur par défaut lorsque l'on quitte la légende.
                onLeave: (e) => { if (e.native!.target! instanceof HTMLElement) e.native!.target!.style.cursor = 'default'; },
                // On affiche la légende.
                display: true,
                labels: {
                    // On veut avoir un border radius sur les carrés de la légende donc on set useBorderRadius à true.
                    useBorderRadius: true,
                    borderRadius: 3,

                    // On pose la largeur et la hauteur des carrés de la légende à 30px.
                    boxWidth: 30,
                    boxHeight: 20,

                    generateLabels: function (chart) {
                        const originalLabels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
        
                        // Filtrez les labels pour n'afficher la légende que pour un seul groupe empilé (stack)
                        return originalLabels.filter(label => !label.text.includes("No Tooltips here"));
                    }
                },
            }
        },
    };
}

/**
 * @brief Fonction permettant de récupérer la valeur maximale des données du Chart.
 * @param scheduleSolution La solution d'ordonnancement.
 * @returns La valeur maximale des données du Chart.
 */
export const getMaxValueFromScheduleSoluition = (scheduleSolution: number[][][]) : number => {
    return scheduleSolution.reduce((max, schedule) => {
        const maxInSchedule = Math.max(...schedule.map((task) => task[1]));

        return Math.max(max, maxInSchedule);
    }, 0);
}

/**
 * @brief Méthode permettant de récupérer la valeur maximale de la solution d'ordonnancement.
 * @param scheduleSolution La solution d'ordonnancement à évaluer.
 * @returns La valeur maximale de la solution d'ordonnancement.
 */
export const getLmax = (scheduleSolution: number[][]) : number => {
    return Math.max(...scheduleSolution.map((task) => task[1]));
}

/**
 * @brief Méthode permettant de foramtter la solution d'ordonnancement pour la visualisation.
 * @param initialArray Le tableau initial à formater.
 * @returns Le tableau formaté.
 */
export const scheduleArrayFormat = (initialArray: number[][][]) : number[][][] => {
    let formatArray: number[][][] = initTriDimensionamlArray(initialArray[0].length);

    for (let i = 0 ; i < initialArray.length ; i++)
        for (let j = 0 ; j < initialArray[0].length ; j++)
            formatArray[initialArray[i][j][2]].push(initialArray[i][j]);

    return formatArray;
}

/**
 * @brief Méthode permettant de remplir le tableau des latences.
 * @param initialArray Le tableau initial à partir duquel on fait le remplissage.
 * @param nbOfRooms Le nombre de salles.
 * @returns Le tableau des latences.
 */
export const fillDueDate = (initialArray: number[][], nbOfRooms: number) => {
    let array: number[][][] = initTriDimensionamlArray(nbOfRooms);

    for (let i = 0 ; i < initialArray.length ; i++) {
        array[initialArray[i][2]].push(initialArray[i])
    }

    return array
}

/**
 * @brief Méthode permettant de formatter le tableau des latences pour la visualisation.
 * @param initialArray Le tableau à formatter.
 * @param nbOfRooms Le nombre de salles.
 * @returns Le tableau formaté.
 */
export const dueDateFormatArray = (initialArray: number[][], nbOfRooms: number): number[][][] => {
    let formatArray: number[][][] = fillDueDate(initialArray, nbOfRooms); 

    const maxLength = Math.max(...formatArray.map(subArr => subArr.length));

    for (const subArr of formatArray) {
        while (subArr.length !== maxLength) {
            subArr.push([0, 0, 0]);
        }
    }

    transpose(formatArray)
    formatArray = formatArray.filter((element: (number[] | undefined)[]) => !element.includes(undefined))

    return formatArray;
}