import error from '../../../assets/image/404-error.svg';
import Footer from '../element/footer';

const Error404Screen = () => {
    return (
        <section className="404-error-container">
            <img src={error} alt="404 error page" className="404-error-image" />
            <Footer/>
        </section>
    );
}

export default Error404Screen;