import React from 'react';
import { Location, Route, Routes, useLocation } from 'react-router-dom';
import HomeScreen from '../page/home-screen';
import ScheduleScreen from '../page/schedule-screen';
import WorkingScreen from '../page/working-page';
import AboutScreen from '../page/about-screen';
import ContactScreen from '../page/contact-screen';
import Error404Screen from '../page/404-screen';
import NavBar from '../component/nav-bar';
import { initSlideMotion } from '../../controller/global/slide-element';

/**
 * @brief Élément permettant l'affaichage du contenu de l'application.
 * @returns Élément permettant l'affaichage du contenu de l'application.
 * 
 * @note J'utilise react-router-dom pour gérer les routes de l'application.
 * @note Il y avait un problème avec le scroll lors de la navigation entre les pages car le scroll était conservé.
 * @note Créer un composant ScrollToTop comme propsé sur internet ne fonctionnait pas correctement, et n'était pas pris en compte sur firefox. ScrollRestoration proposé par react-router-dom ne fonctionnait pas non plus dans mon cas.     
 * @note J'ai donc ajouté un listener sur l'évènement popstate pour forcer le scroll à 0, et que le scroll ne soit pas visible au yeux de l'utilisateur lors du chargement de la page. Il y a donc une latence de 100 ms le temps que le scroll se restore sans être visible par les utilisateurs avant d'afficher le contenu de la page choisi.
 */
const AppContainer = () => {
    const [isLoading, setIsLoading] : [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
        = React.useState(false);
    const location: Location = useLocation();

    React.useEffect(() => initSlideMotion(setIsLoading), [location]);

    return (
        <div id="app-container">
            <NavBar/>
            {
                (isLoading) 
                    ? <></>
                    : <main id="main-content">
                        <Routes>
                            <Route path="/" element={ <HomeScreen/> } />
                            <Route path="/schedule" element={ <ScheduleScreen/> } />
                            <Route path="/working" element={ <WorkingScreen/> } />
                            <Route path="/about" element={ <AboutScreen/> } />
                            <Route path="/contact" element={ <ContactScreen/> } />
                            <Route path="/api/*" element={ <Error404Screen/> } />
                            <Route path="*" element={ <Error404Screen/> } />
                        </Routes>  
                    </main>
            }
        </div>
    );
}

export default AppContainer;
