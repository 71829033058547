import { CsvSizeException } from "../../../exception/input/textarea-size-exception";
import { IInputCsvConstructor } from "../../../interface/constructor/input/input-csv-constructor";
import { positiveIntergerVerification, undefinedAttribute } from "../../tool/setter";
import { errorMessage } from "../../variable/error-message";
import { InputText } from "./input-text";

/**
 * Classe relative à la saisie d'un CSV.
 * 
 * @property _nbOfRows *(number)* : Le nombre de lignes que peut comporter le csv.
 * @property _nbOfColumns *(number)* : Le nombre de colonnes que peut comporter le csv.  
 * 
 * @extends InputText Classe relative à la saisie d'un texte.
 */
export class InputCsv extends InputText {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    /** Le nombre de lignes que peut comporter le csv. */
    private _nbOfRows!: number;

    /** Le nombre de colonnes que peut comporter le csv. */
    private _nbOfColumns!: number;

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputCsvConstructor
    ) {
        super(options);
        this.setNbOfRows(options.nbOfRows);
        this.setNbOfColumns(options.nbOfColumns);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    /**
     * @brief Récupère le nombre de lignes que peut comporter le csv.
     * @return Le nombre de lignes que peut comporter le csv.
     */
    public getNbOfRows() : number
    { return this._nbOfRows; }

    /**
     * @brief Récupère le nombre de colonnes que peut comporter le csv.
     * @return Le nombre de colonnes que peut comporter le csv.
     */
    public getNbOfColumns() : number
    { return this._nbOfColumns; }

    // --------------------------
    // Setter
    // --------------------------

    /**
     * @brief Définit le nombre de lignes que peut comporter le csv.
     * @param nbOfRows Le nombre de lignes que peut comporter le csv.
     */
    public setNbOfRows(nbOfRows: number) : void {
        undefinedAttribute(nbOfRows, this.getKey(), "nbOfRows");      
        positiveIntergerVerification(nbOfRows, this.getKey());
        
        this._nbOfRows = nbOfRows;
    }

    /**
     * @brief Définit le nombre de colonnes que peut comporter le csv.
     * @param nbOfColumns Le nombre de colonnes que peut comporter le csv.
     */
    public setNbOfColumns(nbOfColumns: number) : void {
        undefinedAttribute(nbOfColumns, this.getKey(), "nbOfColumns");
        positiveIntergerVerification(nbOfColumns, this.getKey());

        this._nbOfColumns = nbOfColumns;
    }

    public override setError(error: string) : void {
        // Si le nombre de lignes est invalide, on lève une exception.
        if (this.getNbOfRows() !== 0 && !this.isRowCountValid()) this._error = errorMessage.invalidCsvRowsNumber;

        // Si le nombre de colonnes est invalide, on lève une exception.
        else if (this.getNbOfColumns() !== 0 && !this.isColumnCountValid()) this._error = errorMessage.invalidCsvColumnsNumber;

        else super.setError(error);
    }

    // --------------------------
    // Méthode
    // --------------------------

    /**
     * @brief Méthode permettant de vérifier si le nombre de lignes est valide.
     * @returns True si le nombre de lignes est valide, false sinon.
     */
    public isRowCountValid() : boolean {
        const lines: string[] = this.getValue().split('\n').filter((line: string) => line.trim() !== "");

        return lines.length === this.getNbOfRows();
    }

    /**
     * @brief Méthode permettant de vérifier si le nombre de colonnes est valide.
     * @returns True si le nombre de colonnes est valide, false sinon.
     */
    public isColumnCountValid() : boolean {
        const lines: string[] = this.getValue().split('\n').filter((line: string) => line.trim() !== "");

        for (let line of lines) 
            if (line.split(";").map(element => element.trim()).length !== this.getNbOfColumns()) 
                return false;

        return true;
    }

    public validator() : void {
        super.validator();

        // Si le nombre de colonnes est invalide, on lève une exception.
        if (!this.isColumnCountValid()) throw new CsvSizeException(this.getKey(), "columns", this.getNbOfColumns());

        // Si le nombre de lignes est invalide, on lève une exception.
        if (!this.isRowCountValid()) throw new CsvSizeException(this.getKey(), "rows", this.getNbOfRows());
    }
}