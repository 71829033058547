import { useInView } from "react-intersection-observer";
import { IAboutProblemExplanation, aboutPageExplanationData, aboutProblemExplanationTitle } from "../../../text-resources/about-page/about-problem-explanation";
import Text from "../../component/text/text";
import { Title }from "../../component/text/title";


interface GridRenderProps {
    data: IAboutProblemExplanation
}

const RenderCell = (props: GridRenderProps) => {
    const { ref , inView } = useInView({ triggerOnce: true });   

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-left-hidden'

    return (
        <div ref={ref} className="about-problem-cell-container space-element-large in-view-container">
            <img 
                src={ props.data.image } 
                alt={ props.data.id } 
                className={ props.data.className + " " + inViewClassName + " about-problem-explanation-image left" }
            /> 
            <div key={ props.data.id } className={inViewClassName +" about-problem-explanation-text-container"}>
                <Text text={ props.data.text } />
            </div>
        </div>
    )
}


const AboutProblemExplanation = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassNameRight: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden';

    return (
        <div ref={ref} className="in-view-container about-problem-explanation-container">
            <Title className={"space-title " + inViewClassNameRight} title={ aboutProblemExplanationTitle } />
            {
                aboutPageExplanationData.map((data: IAboutProblemExplanation) => {
                    return (
                        <RenderCell key={ data.id } data={ data }/>
                    )
                })
            }
        </div>   
    );
}

export default AboutProblemExplanation;


/*


{
                aboutPageExplanationData.map((data: IAboutProblemExplanation) => {
                    return (
                        (data.position === 0) 
                            ? <ToRightGridRender key={ data.id } data={ data } />
                            : <ToLeftGridRender key={ data.id } data={ data } />
                    )
                })
            }
*/