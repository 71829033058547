import { NavButtonProps } from '../../../interface/props/button/nav-button-props';
import NavButton from './nav-button';

/**
 * @brief Composant de bouton permettant la navigation entre les différentes pages du site.
 * @param props NavButtonProps : Interface permettant de caractériser un bouton de naviguation.
 * @returns Composant de bouton ayant la couleur principale permettant la navigation entre les différentes pages du site.
 *
 * @prop {string} className : Classe CSS du bouton.
 * @prop {string} to : Lien vers la page de destination.
 * @prop {React.RefObject<HTMLAnchorElement>} navButtonRef : Référence vers le bouton de naviguation.
 * @prop {string} text : Texte du bouton.
 */
const PrimaryNavButton: React.FC<NavButtonProps> = (props: NavButtonProps): JSX.Element => {
    const className: string = `button-fill-primary ${props.className}`;

    return (
        <NavButton  
            text={props.text}
            navButtonRef={props.navButtonRef} 
            className={className} 
            to={props.to}/>
    );
}

export default PrimaryNavButton;