import Footer from "../element/footer";
import WorkingExplanation from "../element/working/working-explanation";
import WorkingHeader from "../element/working/working-header";

const WorkingScreen = () => {
    return (
        <>
            <WorkingHeader/>
            <WorkingExplanation/>
            <Footer/>
        </>
    );
}

export default WorkingScreen;