/**
 * @brief Fonction permettant de formater une chaine de caractère en nombre.
 * @param value La chaine de caractère à formater.
 * @returns La chaine de caractère formatée en nombre.
 */
export const fromStringToNumber = (value: string) : number => {
    return Number(value);
}

/**
 * @brief Fonction permettant de formater une chaine de caractère en tableau de nombres.
 * @param value La chaine de caractère à formater.
 * @returns La chaine de caractère formatée en tableau de nombres.
 */
export const fromCsvToNumberArray = (value: string) : number[] => {
    return value.split(";").map((element: string) => fromStringToNumber(element));
}

/**
 * @brief Fonction permettant de formater une chaine de caractère en un tableau de nombres à deux dimensions.
 * @param csv La chaine de caractère à formater.
 * @returns La chaine de caractère formatée en un tableau de nombres à deux dimensions.
 */
export const fromCsvTo2dNumberArray = (csv: string) : number[][] => {
    let csvArray: string[][] = csv.split("\n").map((row: string) => row.split(";"));
    let csvNumberArray: number[][] = csvArray.map((row: string[]) => row.map((element: string) => fromStringToNumber(element)));

    return csvNumberArray;
}

