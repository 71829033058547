import { SectionTitle }from "../../component/text/title";
import highFive from '../../../../assets/image/high-five.svg';
import TwoCellsGrid from "../../component/container/two-cells-grid";
import Text from "../../component/text/text";
import { getStartedButton, getStartedText, getStartedTitle } from "../../../text-resources/home-page/home-get-started";
import { useInView } from "react-intersection-observer";
import PrimaryNavButton from "../../component/button/primary-nav-button";
import { InViewMotionCellProps } from "../../../interface/props/container/two-cells-grid-props";

const LeftCellContent: React.FC<InViewMotionCellProps> = (props: InViewMotionCellProps) => {
    const inViewClassName: string = (props.inView) 
        ? 'in-view-motion-show in-view-motion-to-top-or-bottom-show' 
        : 'in-view-motion-hidden in-view-motion-to-bottom-hidden'

    const textClassName: string = `home-get-started-text ${(inViewClassName)}`;
    const navButtonClassName: string = `${inViewClassName}`;

    return (
        <> 
            <Text
                className={textClassName}
                text={ getStartedText[0] }
            />
            <PrimaryNavButton
                className={navButtonClassName}
                text={ getStartedButton }
                to="/schedule"
            />
        </>
    );
}

const RightCellContent = () => {
    return (
        <img 
            src={ highFive }
            className="home-get-started-high-five-image" 
            alt="Home get started showing people high fiving." 
        />
    );
}

const HomeGetStarted = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-right-hidden'

    const titleClassName:string = `space-title ${(inViewClassName)}`;
    const rightCellClassName: string = `home-get-started-right-container ${inViewClassName}`;
    const leftCellClassName: string = `${(inViewClassName)}`;

    return (
        <section ref={ref} className="home-get-started-container in-view-container space-element-extra-large">
            <SectionTitle
                className={ titleClassName }
                title={ getStartedTitle }
            />
            <TwoCellsGrid
                rightCellClassName={ rightCellClassName }
                leftCellClassName={ leftCellClassName }
                leftCellContent = { <LeftCellContent inView={inView}/> }
                rightCellContent = { <RightCellContent/> }
            />
        </section>
    );
}

export default HomeGetStarted;