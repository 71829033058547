import puzzle from '../../../../assets/image/puzzle.svg';
import { SectionTitle }from '../../component/text/title';
import TwoCellsGrid from "../../component/container/two-cells-grid";
import Text from "../../component/text/text";
import { useInView } from 'react-intersection-observer';
import { schedulingExplanationText, schedulingExplanationTitle } from '../../../text-resources/home-page/home-scheduling-explanation';

const LeftCellContent = () => {
    return <img 
        src={ puzzle }
        className="home-schedule-explanation-musueum-image"
        alt="Home scheduling explanation showing a man holding a puzzle piece." 
    />;
}

const RightCellContent = () => {
    return <Text text={ schedulingExplanationText[0] }/>;
}

const HomeScheduleExplanation = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-left-or-right-show' 
        : 'in-view-motion-hidden in-view-motion-to-left-hidden'

    const titleClassName:string = `space-title ${(inViewClassName)}`;
    const parentClassName: string = 'home-schedule-explanation-grid-container in-view-container';
    const rightCellClassName: string = `${inViewClassName}`;
    const leftCellClassName: string = `home-schedule-explanation-left-container ${(inViewClassName)}`;

    return (
        <section ref={ref} className="home-schedule-explanation-container space-element-extra-large">
            <SectionTitle
                className={titleClassName}
                title={schedulingExplanationTitle}
            />
            <TwoCellsGrid
                parentClassName={parentClassName}
                leftCellClassName={leftCellClassName}
                rightCellClassName={rightCellClassName}
                leftCellContent = { <LeftCellContent/> }
                rightCellContent = { <RightCellContent/> }
            />
        </section>
    );
}

export default HomeScheduleExplanation;