import { InputText } from "../../model/object/input/input-text";
import { InputCsvInteger } from "../../model/object/input/input-csv-integer";
import { InputInteger } from "../../model/object/input/input-integer";
import { getDueDates, getNbOfRooms, getNbOfVisitorGroups, getProcessingTimes } from "../../text-resources/schedule-page/schedule-input";
import { errorToastBox } from "../global/toast-box";
import { scheduleFormDueDatesToastBoxErrorMessage, scheduleFormNbOfRoomsAndGroupsToastBoxErrorMessage, scheduleFormProcessingTimesToastBoxErrorMessage } from "../../text-resources/schedule-page/taost-box-message";
import { IScheduleForm } from "../../interface/input/schedule-form";
import { post } from "../../api-call/manager/schedule/open-shop";
import React from "react";
import { IScheduleSolution } from "../../interface/adapter/api-to-client/schedule-solution";

/**
 * @brief Méthode permettant de gérer les erreurs au click du bouton permettant l'ordonnancement.
 * @param ref La référerence de l'erreur à mettre à jour.
 * @param input L'input à utiliser pour récupérer l'erreur.
 */
export const handleErrorRefClick = (ref: React.MutableRefObject<any>, input: InputText) : void => {
    ref.current!.innerText = input.getError();
}

/**
 * @brief Méthode permettant de réinitialiser les erreurs au click du bouton permettant l'ordonnancement.
 * @param ref La référerence de l'erreur à mettre à jour.
 */
export const resetErrorRefClick = (ref: React.MutableRefObject<any>) : void => {
    ref.current!.innerText = "";
}

export interface IErrorRef {
    nbOfGroupsRef: React.MutableRefObject<any>,
    nbOfRoomsRef: React.MutableRefObject<any>,
    processingTimesRef: React.MutableRefObject<any>,
    dueDatesRef: React.MutableRefObject<any>
}

export interface IInputRef {
    nbOfGroupsRef: React.MutableRefObject<any>,
    nbOfRoomsRef: React.MutableRefObject<any>,
    processingTimesRef: React.MutableRefObject<any>,
    dueDatesRef: React.MutableRefObject<any>
}

/**
 * @brief Function permettant d'initialiser les inputs de la page de formulaire.
 * @returns Les inputs initialisés de la page de formulaire.
 */ 
export const initInputs = () : IScheduleForm => {
    let nbOfGroups!: InputInteger, 
        nbOfRooms!: InputInteger, 
        processingTimes!: InputCsvInteger, 
        dueDates!: InputCsvInteger;

    try {
        nbOfGroups = getNbOfVisitorGroups();
        nbOfRooms = getNbOfRooms();
        processingTimes = getProcessingTimes();
        dueDates = getDueDates();
    } catch (error) {
        console.error(error);
    }

    return {
        nbOfGroups: nbOfGroups,
        nbOfRooms: nbOfRooms,
        processingTimes: processingTimes,
        dueDates: dueDates
    }
}

/**
 * @brief Function permettant de mettre à jour le nombre de lignes pour le CSV des durées opératoires, ainsi que le nombre de colonnes pour le CSV des durées de livraisons. 
 * @param inputs Les inputs à mettre à jour.
 */
export const groupsOnBlur = (inputs: IScheduleForm) : void => {
    inputs.processingTimes.setNbOfRows(Number(inputs.nbOfGroups.getValue()));
    inputs.dueDates.setNbOfColumns(Number(inputs.nbOfGroups.getValue()));
}

/**
 * @brief Function permettant de mettre à jour le nombre de colonnes pour le CSV des durées opératoires.
 * @param inputs Les inputs à mettre à jour.
 */
export const roomsOnBlur = (inputs: IScheduleForm) : void => {
    inputs.processingTimes.setNbOfColumns(Number(inputs.nbOfRooms.getValue()))
}

/**
 * @brief Function permettant de faire un appel à l'API pour récupérer la solution du problème d'ordonnancement.
 * @param inputs Les inputs à envoyer à l'API.
 * @param setLoading La fonction permettant de mettre à jour le state du loading.
 * @param setShowSchedule La fonction permettant de mettre à jour le state du schedule.
 * @param setRes La fonction permettant de mettre à jour le state du résultat.
 */
const postFetch = async (
    inputs: IScheduleForm, 
    setLoading: React.Dispatch<React.SetStateAction<boolean>>, 
    setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>, 
    setRes: React.Dispatch<React.SetStateAction<IScheduleSolution | null>>
) : Promise<void> => {
    setLoading(true);

    let res: IScheduleSolution | null = await post(inputs);

    setRes(res);
    setShowSchedule(res !== null);
    setLoading(false);
}

/**
 * @brief Function permettant de valider les inputs du formulaire et lever des exceptions le cas échéant.
 * @param inputs Les inputs à valider.
 * @param errorRef Les références des erreurs à mettre à jour pour le visuel.
 * @param setLoading La fonction permettant de mettre à jour le state du loading.
 * @param setShowSchedule La fonction permettant de mettre à jour le state du schedule.
 */
export const schedule = async (
    inputs: IScheduleForm, 
    errorRef: IErrorRef, 
    setLoading: React.Dispatch<React.SetStateAction<boolean>>, 
    setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>, 
    setRes: React.Dispatch<React.SetStateAction<IScheduleSolution | null>>
) : Promise<void> => {
    try {  
        // On valide la saisie pour le nombre de groupes et on reset l'erreur pour le nombre de groupes.      
        inputs.nbOfGroups.validator(); 
        resetErrorRefClick(errorRef.nbOfGroupsRef);

        // On valide la saisie pour le nombre de salles et on reset l'erreur pour le nombre de salles.
        inputs.nbOfRooms.validator();
        resetErrorRefClick(errorRef.nbOfRoomsRef);
        
        // On modifie en conséquence le nombre de lignes et de colonnes pour les durées opératoires.
        inputs.processingTimes.setNbOfRows(Number(inputs.nbOfGroups.getValue()));
        inputs.processingTimes.setNbOfColumns(Number(inputs.nbOfRooms.getValue()));

        // On valide la saisie pour les durées opératoires et on reset l'erreur pour les durées opératoires.
        inputs.processingTimes.validator();
        resetErrorRefClick(errorRef.processingTimesRef);

        // On modifie en conséquence le nombre de lignes et de colonnes pour les durées de livraisons.
        inputs.dueDates.setNbOfColumns(Number(inputs.nbOfGroups.getValue()));

        // On valide la saisie pour les durées de livraisons et on reset l'erreur pour les durées de livraisons.
        inputs.dueDates.validator();
        resetErrorRefClick(errorRef.dueDatesRef);

        // On fait l'appel à l'API pour récupérer la solution du problème d'ordonnancement.
        postFetch(inputs, setLoading, setShowSchedule, setRes);
    } catch (error: any) {
        setLoading(false);

        let errorMessage: string[] = error.message.split(" : ");
        
        if (errorMessage[0] === inputs.nbOfGroups.getKey()) {               
            errorToastBox(scheduleFormNbOfRoomsAndGroupsToastBoxErrorMessage);
            handleErrorRefClick(errorRef.nbOfGroupsRef, inputs.nbOfGroups);
        }

        if (errorMessage[0] === inputs.nbOfRooms.getKey()) {
            errorToastBox(scheduleFormNbOfRoomsAndGroupsToastBoxErrorMessage);
            handleErrorRefClick(errorRef.nbOfRoomsRef, inputs.nbOfRooms);
        }
        
        if (errorMessage[0] === inputs.processingTimes.getKey()) {
            errorToastBox(scheduleFormProcessingTimesToastBoxErrorMessage(inputs.nbOfGroups.getValue(), inputs.nbOfRooms.getValue()));
            handleErrorRefClick(errorRef.processingTimesRef, inputs.processingTimes);
        }

        if (errorMessage[0] === inputs.dueDates.getKey()) {
            errorToastBox(scheduleFormDueDatesToastBoxErrorMessage(inputs.nbOfGroups.getValue()));
            handleErrorRefClick(errorRef.dueDatesRef, inputs.dueDates);
        }
    }
}

/**
 * @brief Méthode permettant de générer un exemple de problème d'ordonnancement.
 * @param inputs Les inputs à mettre à jour.
 * @param inputRef Les références des inputs à mettre à jour.
 */
export const generateExample = (inputs: IScheduleForm, inputRef: IInputRef) : void => {
    const nbOfGroups: number = Math.floor(2 + Math.random() * 8);
    const nbOfRooms: number = Math.floor(2 + Math.random() * 8);

    let processingTimes: string = "";
    let dueDates: string = "";

    for (let i = 0 ; i < nbOfGroups ; i++) {
        for (let j = 0 ; j < nbOfRooms ; j++) {
            processingTimes += Math.floor(15 + Math.random() * 30);

            if (j !== nbOfRooms - 1) processingTimes += ";";
        }

        processingTimes += "\n";

        dueDates += Math.floor(2 + Math.random() * 8);
    
        if (i !== nbOfGroups - 1) dueDates += ";";
    }    

    try {
        inputs.nbOfGroups.setValue(`${nbOfGroups}`);
        inputs.nbOfRooms.setValue(`${nbOfRooms}`);
        inputs.processingTimes.setValue(processingTimes);
        inputs.dueDates.setValue(dueDates);

        inputRef.nbOfGroupsRef.current!.value = `${nbOfGroups}`;
        inputRef.nbOfRoomsRef.current!.value = `${nbOfRooms}`;
        inputRef.processingTimesRef.current!.value = processingTimes;
        inputRef.dueDatesRef.current!.value = dueDates;
    } catch (error: any) {
        errorToastBox("Ups, something went wrong. Please try again later.");
    }
}