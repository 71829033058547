import { contactHeader } from "../../../text-resources/contact-page/contact-header";
import Text from "../../component/text/text";
import { Title }from "../../component/text/title";
import logo from "../../../../assets/image/logo.svg";
import { useInView } from "react-intersection-observer";
import NavButton from "../../component/button/nav-button";

const ContactHeader = () => {
    const { ref , inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-top-or-bottom-show' 
        : 'in-view-motion-hidden in-view-motion-to-bottom-hidden'

    const imageClassName: string = `contact-header-image ${inViewClassName}`;
    const titleClassName: string = `contact-header-title ${(inViewClassName)}`;
    const textClassName: string = `text-align-center ${inViewClassName} space-element-medium`;

    return (
        <header ref={ref} className="contact-header-container in-view-container space-element-large">
            <img className={imageClassName} src={ logo } alt="logo"/>
            <Title className={titleClassName} title={ contactHeader.title }/>
            <Text className={textClassName} text={ contactHeader.text }/>
            <div className={inViewClassName + " contact-social-network"}>
                <NavButton className="button button-fill-secondary button-circular" text="in" to="https://www.linkedin.com/in/sabrina-a-8614111b1"/>            
            </div>
        </header>   
    );
}

export default ContactHeader;