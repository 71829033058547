import { NavLink } from 'react-router-dom';
import { NavButtonProps } from '../../../interface/props/button/nav-button-props';

/**
 * @brief Composant de bouton permettant la navigation entre les différentes pages du site.
 * @param props NavButtonProps : Interface permettant de caractériser un bouton de naviguation.
 * @returns Composant de bouton permettant la navigation entre les différentes pages du site.
 * 
 * @prop {string} className : Classe CSS du bouton.
 * @prop {string} to : Lien vers la page de destination.
 * @prop {React.RefObject<HTMLAnchorElement>} navButtonRef : Référence vers le bouton de naviguation.
 * @prop {string} text : Texte du bouton.
 */
const NavButton: React.FC<NavButtonProps> = (props: NavButtonProps): JSX.Element => {
    const className: string = `button ${props.className}`;

    return (
        <NavLink ref={props.navButtonRef} className={className} to={props.to}>
            {
                (props.text !== undefined && props.text !== null) 
                    ? props.text
                    : ""
            }
        </NavLink>
    );
}

export default NavButton;