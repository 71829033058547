import { useInView } from "react-intersection-observer";
import { CardProps } from '../../../interface/props/card/card-props';
import Card from '../card/card';
import { CardsContainerProps } from "../../../interface/props/container/card-container-props";

interface CardRenderProps {
    card: CardProps
}

const CardRender: React.FC<CardRenderProps> = (props: CardRenderProps) => {
    const { ref, inView } = useInView({ triggerOnce: true });

    const inViewClassName: string = (inView) 
        ? 'in-view-motion-show in-view-motion-to-top-or-bottom-show' 
        : 'in-view-motion-hidden in-view-motion-to-bottom-hidden';

    const cardClassName = `home-card-item in-view-container ${inViewClassName} ${props.card.containerClassName}`;

    return <Card
        cardRef={ref}
        iconPath = { props.card.iconPath }
        title = { props.card.title }
        text = { props.card.text }    
        containerClassName = {cardClassName}
        iconClassName={inViewClassName}
        textClassName={inViewClassName}
        titleClassName={inViewClassName}
    />
}

/**
 * @brief Composant d'un contenant permettant l'affichage d'un ensemble de cartes.
 * @param props CardProps : Interface permettant de caractériser un contenant pour l'affichage d'un ensemble de cartes.
 * @returns Composant d'un contenant permettant l'affichage d'un ensemble de cartes.
 * 
 * @prop {CardProps[]} cards : Liste des cartes à afficher.
 * @prop {string} className : Classe CSS du conteneur de la carte.
 */
const CardContainer: React.FC<CardsContainerProps> = (props: CardsContainerProps) => {
    const className: string = `cards-container ${props.className}`;

    return (
        <div className={className}>
            {
                props.cards.map((card: CardProps) => {
                    return <CardRender key={card.title} card={card} />
                })
            }
        </div>  
    );
}

export default CardContainer;