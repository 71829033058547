import React from "react";
import { InputText } from "../../../../model/object/input/input-text";
import { InputContext, InputContextProps } from "../../../context/input-context";
import { InputTextareaTypeProps } from "../../../../interface/props/input/input-textarea-type-props";
import { onChangeInputTextType } from "../../../../controller/schedule/schedule-inputs";

/**
 * @brief Composant permettant la saisie de texte, etc. à l'intérieur d'une zone de texte.
 * @param props InputTextareaTypeProps : Interface permettant de caractériser un input permettant une saisie de texte, mail, nombre, ... à l'intérieur d'une zone de texte.
 * @returns Composant permettant la saisie de texte, etc. à l'intérieur d'une zone de texte.
 * 
 * @prop {boolean} readonly : Détermine si oui ou non l'input est en lecteur seul.
 * @prop {boolean} disabled : Détermine si oui ou non l'input est désactivé.
 * @prop {boolean} autofocus : Détermine si oui ou non l'input est en autofocus.
 * @prop {string} value : La valeur de l'input.
 * @prop {string} placeholder : Le placeholder de l'input.
 * @prop {string} className : La classe de l'input.
 * @prop {number} rows : Le nombre de lignes de l'input.
 * @prop {number} cols : Le nombre de colonnes de l'input.
 * @prop {React.FocusEventHandler | undefined} onBlur : La fonction permettant de gérer un évènement lorsque le focus y est perdu.
 */
const InputTextareaType: React.FC<InputTextareaTypeProps> = (props: InputTextareaTypeProps): JSX.Element => {
    // Utilisation du contexte.
    const context: InputContextProps = React.useContext(InputContext)!;
    const input: InputText = context.input;
    const setError: React.Dispatch<React.SetStateAction<string>> = context.setError;

    return (
        <div className="input-text-container">
            <textarea
                ref={props.inputRef as any}
                name={input.getKey()}
                id={input.getKey()}
                className={"input-text input-textarea " + props.className} 
                readOnly={props.readonly}
                disabled={props.disabled}
                autoFocus={props.autofocus}
                value={props.value}
                placeholder={props.placeholder}
                rows={props.rows}
                cols={props.cols}
                onChange={(e) => onChangeInputTextType(e, input, setError)}/>
        </div>
    );
};

export default InputTextareaType;