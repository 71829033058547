import { CardProps } from "../../interface/props/card/card-props";
import iconTimeOpti from '../../../assets/image/icon-time-opti.svg';
import iconResAlloc from '../../../assets/image/icon-res-alloc.svg';
import iconProductivity from '../../../assets/image/icon-productivity.svg';
import iconPrioOrga from '../../../assets/image/icon-prio-orga.svg';
import iconAvoidConflict from '../../../assets/image/icon-avoid-conflict.svg';

export const cardTitle: string = "And the necessity ?";

export const cardsEn: CardProps[] = [
    {
        iconPath: iconTimeOpti,
        title: "Time optimization",
        text: "Scheduling helps optimize the allocation of time by efficiently planning and managing tasks, activities, or appointments. It ensures that activities are completed in a timely manner and deadlines are met.",  
        containerClassName: "grid-cell-1-1"
    },
    {
        iconPath: iconResAlloc,
        title: "Resource allocation",
        text: "Scheduling allows for better allocation and utilization of resources such as personnel, equipment, or facilities. It helps ensure that the right resources are available at the right time, reducing inefficiencies and maximizing productivity.",
        containerClassName: "gtrid-cell-1-2"
    },
    {
        iconPath: iconPrioOrga,
        title: "Prioritization and organization",
        text: "Scheduling enables prioritization of tasks and activities, ensuring that important or time-sensitive items are given appropriate attention. It helps in organizing workflows and maintaining a structured approach to managing various responsibilities.",
        containerClassName: "grid-cell-2-1"
    },
    {
        iconPath: iconAvoidConflict,
        title: "Avoiding conflicts",
        text: "Scheduling helps prevent conflicts or overlaps in appointments, meetings, or tasks. By having a centralized view of the schedule, it becomes easier to identify and resolve any potential conflicts in advance.",
        containerClassName: "grid-cell-2-2"
    },
    {
        iconPath: iconProductivity,
        title: "Enhanced productivity",
        text: "Scheduling enables for better time management by planning and organizing various tasks, thereby freeing up time to fully concentrate on a specific activity without constant interruptions. By avoiding these frequent interruptions, it is possible to maintain concentration and workflow, resulting in increased productivity.",
        containerClassName: "grid-cell-3-1"
    }
];