export const titles = {
    explanationSection:  "How does it work ?"
}

export const explanationText: string[] = [
    "Let's imagine that we need to plan the visit of 7 groups of visitors in a museum with 5 rooms. So, first of all, we need to enter these data:",

    "Once this data is entered, we need to provide the duration for each group in each corresponding room. If a group is not supposed to visit a room, it is necessary to set the duration to 0. Additionally, it is possible to add an additional duration corresponding, for example, to the time needed to return to the entrance. By default, we can set these additional durations to zero. Let's assume we have the following durations for each of the 7 groups:",

    "The aim is to enter data in CSV format. The inputs will then be entered as follows:",

    "Finally, you just need to press the « Schedule » button, and the algorithm will take care of finding a solution that approximates optimality to schedule these groups of visitors in your museum. After pressing the button, in our case, the algorithm gives us the following schedule:",

    "We ultimately obtain a scheduling of all visitor groups for each of the rooms in the form of a Gantt chart. With the data from this example, which includes 7 groups and 5 rooms, it is possible to arrange the groups to visit the rooms in 158 minutes, taking into account the return to the starting point for each group. Please note that if you wish to organize your visitor groups without considering the return time to the starting point, simply set the corresponding field with 0 as value for each group"
]

export const inputText = {
    group: {
        text: "Enter number of visitor groups :",
        value: "7"
    },

    room: {
        text: "Enter number of rooms to visit :",
        value: "5"
    },

    processingTimes: {
        text: "Enter the durations that each groups should spend in a room :",
        value: "19 ; 14 ; 27 ; 21 ; 19 \n22 ; 24 ; 26 ; 16 ; 14 \n26 ; 13 ; 16 ; 22 ; 23 \n18 ; 11 ; 17 ; 14 ; 23 \n11 ; 21 ; 30 ; 22 ; 29 \n19 ; 30 ; 18 ; 11 ; 18 \n12 ; 19 ; 23 ; 21 ; 11 "
    },

    dueDates: {
        text: "Enter times to start point for each visitor group :",
        value: "2 ; 3 ; 5 ; 1 ; 4 ; 7 ; 3"
    }
}

export const notes = {
    durationsExpression: "The durations are expressed in minutes.",
    dueDateBarDesign: "The red bars represent the durations of the return to the starting point for each of the visitor groups located directly to the left of the corresponding small red bar."
}

export const dataExample = {
    processingTimes: [
        [ "", "R1" , "R2" , "R3" , "R4" , "R5" ],
        [ "G1", "19" , "14" , "27" , "21" , "19" ], 
        [ "G2", "22" , "24" , "26" , "16" , "14" ], 
        [ "G3", "26" , "13" , "16" , "22" , "23" ], 
        [ "G4", "18" , "11" , "17" , "14" , "23" ], 
        [ "G5", "11" , "21" , "30" , "22" , "29" ], 
        [ "G6", "19" , "30" , "18" , "11" , "18" ], 
        [ "G7", "12" , "19" , "23" , "21" , "11" ]
    ],
    dueDates: [
        [ "", "Duration to\nstart point" ],
        [ "G1", "2" ],
        [ "G2", "3" ],
        [ "G3", "5" ],
        [ "G4", "1" ],
        [ "G5", "4" ],
        [ "G6", "7" ],
        [ "G7", "3" ]
    ],
    scheduleSolution: [
        [[88,107,0],[66,88,1],[116,142,2],[48,66,3],[0,11,4],[29,48,5],[11,23,6],],[[107,121,0],[121,145,1],[81,94,2],[0,11,3],[11,32,4],[51,81,5],[32,51,6],],[[42,69,0],[16,42,1],[0,16,2],[140,157,3],[69,99,4],[122,140,5],[99,122,6],],[[16,37,0],[0,16,1],[94,116,2],[116,130,3],[37,59,4],[81,92,5],[59,80,6],],[[132,151,0],[89,103,1],[29,52,2],[66,89,3],[103,132,4],[11,29,5],[0,11,6],]
    ],
    dueDatesSchedule: [
        [151,153,4,0],[145,148,1,1],[142,147,0,2],[157,158,2,3],[132,136,4,4],[140,147,2,5],[122,125,2,6] 
    ]
}
