import Text from '../component/text/text';

/**
 * @brief Élément permettant l'affaichage du contenu de l'application.
 * @returns Élément permettant l'affaichage du contenu de l'application.
 * 
 * @note J'utilise react-router-dom pour gérer les routes de l'application.
 * @note Il y avait un problème avec le scroll lors de la navigation entre les pages car le scroll était conservé.
 * @note Créer un composant ScrollToTop comme propsé sur internet ne fonctionnait pas correctement, et n'était pas pris en compte sur firefox. ScrollRestoration proposé par react-router-dom ne fonctionnait pas non plus dans mon cas.     
 * @note J'ai donc ajouté un listener sur l'évènement popstate pour forcer le scroll à 0, et que le scroll ne soit pas visible au yeux de l'utilisateur lors du chargement de la page. Il y a donc une latence de 100 ms le temps que le scroll se restore sans être visible par les utilisateurs avant d'afficher le contenu de la page choisi.
 */
const Footer = () => {
    return (
        <div className="footer-container">
            <hr/>
            <Text text='© 2023 - All rights reserved' className='text-italic'/>
        </div>
    );
}

export default Footer;
