import { ChartOptions } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { chartJsPlugin, generateDatasetForChart, generateOptionsForHorizontalChartBar } from '../../controller/global/schedule-visualization';
import { ScheduleVisualizationProps } from '../../interface/props/schedule-visualization';

Chart.register(chartJsPlugin, ...registerables);

/**
 * @brief Composant permettant d'afficher la solution d'ordonnancement.
 * @param props ScheduleVisualizationProps : Interface permettant de caractériser une solution d'ordonnancement.
 * @returns Composant permettant d'afficher la solution d'ordonnancement.
 * @remark La solution d'ordonnancement est affichée sous forme de diagramme de Gantt.
 * 
 * @prop {string} className : La classe CSS appliquée au contenant du chart.
 * @prop {number[][][]} scheduleSolution : La solution d'ordonnancement.
 * @prop {number[][][]} dueDates : Les dates de retour au point de départ pour chacun des groupes.
 * 
 * @note La solution prise en compte doit être de la forme suivante :
 * 
 * ```ts
 * [
 *          [ // Salle 1
 *              [start, end, groupId], // Groupe groupId
 *              [start, end, groupId], // Groupe groupId
 *          ],
 *          [ // Salle 2
 *              [start, end, groupId], // Groupe groupId
 *              [start, end, groupId], // Groupe groupId
 *          ],
 *          ...
 * ]
 * ```
 */
const ScheduleVisualization: React.FC<ScheduleVisualizationProps> = (props: ScheduleVisualizationProps) => {
    const data: any = generateDatasetForChart(props);
    const options: ChartOptions<'bar'> = generateOptionsForHorizontalChartBar(props.scheduleSolution);

    return (
        <div style={{ height: props.scheduleSolution[0].length * (40 + 30) + 100 }} className={props.className}>
            <Bar data={data} options={options} style={{
                width: '100%',
                height: '100%'
            }} />
        </div>
    );
};

export default ScheduleVisualization;
