import { TextProps } from "../../../interface/props/text/text-props";

/**
 * @brief Composant permettant d'afficher du texte.
 * @param props TextProps : Interface permettant de caractériser un texte
 * @returns Composant permettant d'afficher du texte.
 * 
 * @prop {string} text : Texte à afficher.
 * @prop {string} className : Classe CSS du conteneur du texte.
 * @prop {any} textRef : Référence vers le composant HTML du texte.
 */
const Text: React.FC<TextProps> = (props: TextProps): JSX.Element => {
    const className: string = `text-container ${props.className}`;

    return (
        <p ref={props.textRef} className={className}>
            {props.text}
        </p>
    );
}

export default Text;