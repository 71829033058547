import { errorMessage } from "../../model/variable/error-message";

/**
 * Classe d'exception permettant de retourner un message stipulant que le nombre de caractères d'une zone de texte n'est pas correcte.
 * 
 * @extends Error Classe d'exception.
 */
export class CharacterNumberException extends Error {

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(keyName: string, nbOfCharacters: number) {
        super(`${keyName} : ${errorMessage.invalidCharactersNumber} (max: ${nbOfCharacters} characters)`);
        Object.setPrototypeOf(this, CharacterNumberException.prototype);
    }
}