import { IInputCsvNumberConstructor } from "../../../interface/constructor/input/input-csv-number-constructor";
import { regexCsvNumber } from "../../variable/regex";
import { InputCsvNumber } from "./input-csv-number";

/**
 * Classe relative à la saisie d'un CSV ne pouvant comporter que des nombres entiers.
 * 
 * @extends InputCsvNumber Classe relative à la saisie d'un CSV ne pouvant comporter que des nombres.
 */
export class InputCsvInteger extends InputCsvNumber {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputCsvNumberConstructor
    ) {
        super(options);
        this.setRegex(regexCsvNumber.integer);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    // --------------------------
    // Setter
    // --------------------------

    // --------------------------
    // Méthode
    // --------------------------
}