import { IInputCsvNumberConstructor } from "../../../interface/constructor/input/input-csv-number-constructor";
import { errorMessage } from "../../variable/error-message";
import { regexCsvNumber } from "../../variable/regex";
import { InputCsv } from "./input-csv";

/**
 * Classe relative à la saisie d'un CSV ne pouvant comporter que des nombres.
 * 
 * @extends InputTextarea Classe relative à la saisie d'un CSV.
 */
export class InputCsvNumber extends InputCsv {

    // --------------------------
    // Déclaration des attributs
    // --------------------------

    // --------------------------
    // Constructeur
    // --------------------------

    constructor(
        options: IInputCsvNumberConstructor
    ) {
        super(options);
        this.setRegex(regexCsvNumber.real);
    }
    
    // --------------------------
    // Getter
    // --------------------------

    // --------------------------
    // Setter
    // --------------------------

    // --------------------------
    // Méthode
    // --------------------------

    protected override formatErrorMessage() : string {
        let message: string = "";

        const isNonNegative: boolean = this.getSpecificPatten().includes(regexCsvNumber.nonNegative)
            || this.getSpecificPatten().includes(regexCsvNumber.strictlyNonNegative);

        const isNegative: boolean = this.getSpecificPatten().includes(regexCsvNumber.negative)
            || this.getSpecificPatten().includes(regexCsvNumber.strictlyNegative);

        if (isNonNegative) message = errorMessage.invalidCsvNonNegativeNumber;
        else if (isNegative) message = errorMessage.invalidCsvNegativeNumber;

        return message;
    }
}