import { Location, NavLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/image/logo.svg';
import NavButton from './button/nav-button';
import React from 'react';

/**
 * @brief Composant de barre de navigation permettant la navigation entre les différentes pages du site.
 * @returns Composant de barre de navigation permettant la navigation entre les différentes pages du site.
 */
const NavBar = () => {
    // On gère ici l'affichage du menu sur mobile lorsqu'une route change.
    const [ displayMenu, setDisplayMenu ] : [boolean, React.Dispatch<React.SetStateAction<boolean>>] 
        = React.useState(false);
        
    const location: Location = useLocation();

    React.useEffect(() => setDisplayMenu(false), [location]);

    return (
        <nav className="nav">
            <input onChange={(e: any) => setDisplayMenu(e.target.checked)} checked={displayMenu} type="checkbox" id="nav-bar-checkbox"/>
            <label htmlFor="nav-bar-checkbox" className="nav-bar-btn">
                <span className="nav-bar-line line-1"></span>
                <span className="nav-bar-line line-2"></span>
                <span className="nav-bar-line line-3"></span>
            </label>

            <div className="nav-bar-container">
                <div className="nav-bar-logo-container">
                    <img 
                        src={logo}
                        className="nav-bar-logo" 
                        alt="logo" 
                    />
                    <p className="nav-bar-logo-text">
                        Time To <br/> Schedule!
                    </p>
                </div>

                <div className="nav-bar-link-container">
                    <NavLink className="nav-bar-link" to="/">Home</NavLink>
                    <NavLink className="nav-bar-link" to="/schedule">Schedule</NavLink>
                    <NavLink className="nav-bar-link" to="/working">Working</NavLink>
                    <NavLink className="nav-bar-link" to="/about">About</NavLink>
                </div>

                <div className="nav-bar-contact-container">
                    <NavButton
                        className="nav-bar-contact button button-fill-secondary"
                        text="Contact"
                        to="/contact"
                    />
                </div>
            </div>
        </nav>
    );
}

export default NavBar;