import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const errorToastBox = (message: string = "") : void => {
    toast(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
            background: "#ae5252",
            color: "white"
        },
        progressStyle: {
            background: "white"
        }
    });
}

export const defaultToastBox = (message: string = "") => {
    toast(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
            whiteSpace: "pre-wrap"
        }
    });
}