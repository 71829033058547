import React from "react";
import InputError from "../input-error";
import { InputBaseProps } from "../../../../interface/props/input/input-base-props";
import { InputContext } from "../../../context/input-context";
import InputLabel from "../input-label";

/**
 * @brief Composant relatif à la constitution de base d'un input. Peut importe le type de l'input (text, radio,...), il sera toujours visualisé avec le label, puis l'affichage de la saisie, puis l'erreur correspondante le cas échéant.
 * @param props TwoCellsGridProps : Interface permettant de caractériser la base d'un input.
 * @returns Composant relatif à la constitution de base d'un input.
 * 
 * @prop {InputType} input : L'input correspondant à mettre en oeuvre.
 * @prop {React.ReactNode} children : Les enfants potentiel de l'input.
 * @prop {string} className : Le nom de la classe CSS à appliquer à la base de l'input.
 * @prop {React.RefObject} errorRef : La référence de l'erreur de l'input.
 * @prop {boolean} displayInputConstraints : Détermine si on affiche ou non une aide pour comprendre ce que réalise l'input.
 * @prop {string} inputContraintsExplanationMessage : Le texte à afficher dans une box afin de mieux comprendre comment remplir l'input.
 */
const InputBase: React.FC<InputBaseProps> = (props: InputBaseProps) : JSX.Element => {
    const className: string = "input-base-component-container " + props.className;

    const [error, setError]: [string, React.Dispatch<React.SetStateAction<string>>] 
        = React.useState<string>("");

    return (
        <div className={className}>
            <InputLabel
                input={props.input}
                displayInputConstraints={props.displayInputConstraints}
                inputContraintsExplanationMessage={props.inputContraintsExplanationMessage}
            />

            <InputContext.Provider value={{
                input: props.input,
                setError: setError
            }}>
                {props.children}
            </InputContext.Provider>

            <InputError errorRef={props.errorRef} errorMessage={error}/>
        </div>
    );
};

export default InputBase;