/**
 * Regex relatif à la saisie de nombre pouvant être utilisée à travers l'application.
 */
export const regexNumber = {
    /**
     * On vérifie que l'on a bien un entier, qu'il soit positif ou négatif.
     * 
     * ```
     * /^-?\d+$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^-?\d+$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `-?` : Permet de vérifier que l'on a bien 0 ou 1 fois le signe -.
     * * `\d+` : Permet de vérifier que l'on a bien au moins un chiffre.
     * * `$` : Fin de la Regex. 
     */
    integer : /^-?\d+$/,

    /**
     * On vérifie que l'on a bien un reel, qu'il soit positif ou négatif.
     * 
     * ```
     * /^-?\d*[,.]?\d+$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^-?\d*[,.]?\d+$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `-?` : Permet de vérifier que l'on a bien 0 ou 1 fois le signe -.
     * * `\d*` : Permet de vérifier que l'on a bien au moins un chiffre.
     * * `[,.]?` : Permet de vérifier que l'on a bien 0 ou 1 fois la virgule ou le point.
     * * `\d+` : Permet de vérifier que l'on a bien au moins un chiffre.
     * * `$` : Fin de la Regex.
     */
    real : /^-?\d*[,.]?\d+$/,

    /**
     * On vérifie que l'on a bien un nombre posittif
     * 
     * ```
     * /^\d*[,.]?\d+$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^\d*[,.]?\d+$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `\d*` : Permet de vérifier que l'on a bien au moins un chiffre.
     * * `[,.]?` : Permet de vérifier que l'on a bien 0 ou 1 fois la virgule ou le point.
     * * `\d+` : Permet de vérifier que l'on a bien au moins un chiffre.
     * * `$` : Fin de la Regex.
     */
    nonNegative : /^\d*[,.]?\d+$/,
 
    /**
     * On vérifie que l'on a bien un nombre négatif.
     * 
     * ```
     * /^-\d*\.?\d+|0(?:\.0{1,2})?$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^-\d*\.?\d+|0(?:\.0{1,2})?$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `-\*\.?\d+` : Permet de vérifier que l'on a bien un nombre négatif.
     * * `|` : Opérateur `OR`, va nous permettre d'avoir soit un nombre négatif, soit 0 (ou une représentation décimal de 0).
     * * `0(?:\.0{1,2})?` : Permet de vérifier que l'on a bien 0 ou une représentation décimal de 0.
     * * `$` : Fin de la Regex.
     */
    negative : /^-\d*\.?\d+|0(?:\.0{1,2})?$/,

    /**
      * On vérifie que l'on a bien un nombre strictement positif.
      * 
      * ```
      * /^(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/
      * ```
      * 
      * ---
      * ---
      * 
      * Explication de la RegEx :
      * 
      * ```
      * /^(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/
      * ```
      * 
      * * `^` : Début de la Regex.
      * * `(?!0+(\.[0]+)?$)` : Cette partie permet de rejeter les possibilités d'avoir 0 ou 0.000...
      * * `(?:0|[1-9][0-9]*)` : Cette partie permet d'obtenir 0 ou une suite de chiffres strictement positif.
      * * `(?:\.[0-9]+)?` : Cette partie permet de génèrer un nombre réel. 
      * * `$` : Fin de la Regex.
      */
    strictlyNonNegative : /^(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/,

    /**
      * On vérifie que l'on a bien un nombre strictement positif.
      * 
      * ```
      * /^-(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/
      * ```
      * 
      * ---
      * ---
      * 
      * Explication de la RegEx :
      * 
      * ```
      * /^-(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/
      * ```
      * 
      * * `^` : Début de la Regex.
      * * `-` : Permet de vérifier que l'on a bien un nombre négatif.
      * * `(?!0+(\.[0]+)?$)` : Cette partie permet de rejeter les possibilités d'avoir 0 ou 0.000...
      * * `(?:0|[1-9][0-9]*)` : Cette partie permet d'obtenir 0 ou une suite de chiffres strictement positif.
      * * `(?:\.[0-9]+)?` : Cette partie permet de génèrer un nombre réel. 
      * * `$` : Fin de la Regex.
      */
    strictlyNegative : /^-(?!0+(\.[0]+)?$)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?$/
}

/**
 * Regex relatif à la saisie d'un CSV en zone de texte concernant la saisie de nombre pouvant être utilisée à travers l'application.
 */
export const regexCsvNumber = {
    /**
     * On vérifie que l'on a bien un CSV composé uniquement de nombre entiers, qu'ils soient positifs ou négatifs.
     * 
     * ```
     * /^(?:([-]?\d{1,6}(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^(?:([-]?\d{1,6}(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `[-]?` : Permet un signe négatif - optionnel.
     * * `\d{1,6}` : Permet d'avoir 1 ou au plus 6 chiffres.
     * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
     * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
     * * `$` : Fin de la Regex. 
     */
    integer : /^(?:([-]?\d{1,6}(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/,

    /**
     * On vérifie que l'on a bien un CSV composé uniquement de nombre décimaux, qu'ils soient positifs ou négatifs.
     * 
     * ```
     * /^(?:([-]?\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^(?:([-]?\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `[-]?` : Permet un signe négatif - optionnel.
     * * `\d*` : Permet d'avoir 0 ou plusieurs chiffres avant la decimal.
     * * `\.?` : Permet d'avoir un point optionnel pour un nombre decimal.
     * * `\d+` : Permet d'avoir un ou plusieurs chiffres après la decimal.
     * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
     * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
     * * `$` : Fin de la Regex.
     */
    real : /^(?:([-]?\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/,

    /**
     * On vérifie que l'on a bien un nombre positif.
     * 
     * ```
     * /^(?:(\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^(?:(\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `\d*` : Permet d'avoir 0 ou plusieurs chiffres avant la decimal.
     * * `\.?` : Permet d'avoir un point optionnel pour un nombre decimal.
     * * `\d+` : Permet d'avoir un ou plusieurs chiffres après la decimal.
     * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
     * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
     * * `*` : On peut avoir 0 ou plusieurs fois l'ensemble précédent.
     * * `$` : Fin de la Regex.
     */
    nonNegative : /^(?:(\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/,
 
    /**
     * On vérifie que l'on a bien un nombre négatif.
     * 
     * ```
     * /^(?:(-\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,10}$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^(?:(-\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,10}$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `-?` : Permet un signe négatif - optionnel.
     * * `\d*` : Permet d'avoir 0 ou plusieurs chiffres avant la decimal.
     * * `\.?` : Permet d'avoir un point optionnel pour un nombre decimal.
     * * `\d+` : Permet d'avoir un ou plusieurs chiffres après la decimal.
     * * `|0(?:\.0{1,2})?` : Permet de matcher avec un 0 ou une représentation décimal de 0.
     * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
     * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
     * * `$` : Fin de la Regex.
     */
    negative : /^(?:(-\d*\.?\d+(?: ){0,10})(?:[;](?: ){0,10}|\n|$)){0,1000}$/,

    /**
      * On vérifie que l'on a bien .
      * 
      * ```
      * /^(?:(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/
      * ```
      * 
      * ---
      * ---
      * 
      * Explication de la RegEx :
      * 
      * ```
      * /^(?:(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/
      * ```
      * 
      * * `^` : Début de la Regex.
      * * `(?!0+(\.0+)?$)` : Permet d'exclure les 0 et les représentations décimales de 0.
      * * `(?!0(\.0+)?\s)` : Permet d'exclure les 0.0 suivis d'un espace.
      * * `(?:0|[1-9][0-9]*)` : Permet d'avoir un 0 ou un nombre entier positif.
      * * `(?:\.[0-9]+)?` : Permet d'avoir un nombre décimal positif.
      * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
      * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
      * * `$` : Fin de la Regex.
      */
    strictlyNonNegative : /^(?:(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/,

    /**
      * On vérifie que l'on a bien un nombre strictement positif.
      * 
      * ```
      * /^(?:-(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/
      * ```
      * 
      * ---
      * ---
      * 
      * Explication de la RegEx :
      * 
      * ```
      * /^(?:-(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/
      * ```
      * 
      * * `^` : Début de la Regex.
      * * `-` : Permet d'avoir un signe négatif.
      * * `(?!0+(\.0+)?$)` : Permet d'exclure les 0 et les représentations décimales de 0.
      * * `(?!0(\.0+)?\s)` : Permet d'exclure les 0.0 suivis d'un espace.
      * * `(?:0|[1-9][0-9]*)` : Permet d'avoir un 0 ou un nombre entier positif.
      * * `(?:\.[0-9]+)?` : Permet d'avoir un nombre décimal positif.
      * * `(?: ){0,10}` : Permet d'avoir 0 ou au plus 10 espaces ou sauts de ligne.
      * * `(?:[;](?: ){0,10}|\n|$)` : Permet d'avoir un `;` ou un saut de ligne ou  une fin de la regex.
      * * `$` : Fin de la Regex.
      */
    strictlyNegative : /^(?:-(?!0+(\.0+)?$)(?!0(\.0+)?\s)(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?: ){0,10}(?:[;](?: ){0,10}|\n|$)){0,10}$/
}

/**
 * Regex diverses pouvant être utilisées à travers l'application.
 */
export const regexBasis = {

    /**
     * La regex suivante accepte n'importe quel caractère.
     * 
     * ```
     * /^.*$/
     * ```
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^.*$/
     * ```
     * 
     * * `^` : Début de la Regex.
     * * `.*` : Permet de matcher n'importe quel caractère (`.`) zero ou plusieurs fois (`*`).
     * * `$` : Fin de la Regex.
     */
    default: /^.*$/,

    defaultCsv: /^.*$/,

    /**
     * On vérifie que dans la première partie de mail on n'a pas de caractère spécial :
     * 
     * ```
     * <>()[]\.,;:\s@"
     * ```
     * 
     * Si un `.` est présent, alors on prend garde à ne pas trouver une nouvelle fois des caractères spéciaux.
     * 
     * On ne veux pas non plus de succession de points.
     * 
     * Après le `@`, il est possible d'avoir le formatage suivant:
     * 
     * `195.128.12.64`
     * 
     * ou le formatage suivant :
     * 
     * `dzea8d9z.com` ou encore `etu.univ.bla.bla.fr`
     * 
     * ---
     * --- 
     * 
     * Explication de la RegEx : 
     * 
     * ```
     * /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
     * ```
     * 
     * * `^` : Début de la regex
     * * `\.[^<>()\[\]\\.,;:\s@"]+` : On ne veut pas les symboles suivants => `<>()[]\.,;:\s@"`
     * > * `\s` : White space => on ne veut pas de whitespace
     * > * `+` : Un ou plus
     * > * `\.` : Signifie que l'on peut avoir une première partie d'adresse mail du type bla.bla
     * * `|` : Opérateur OR
     * * `(".+")` : Une succession de point
     * * `@` : Le `@` du mail
     * * `(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])` : Formatage du type 195.128.12.64
     * * `([a-zA-Z\-0-9]+\.)+` : Une succession d'une suite de lettre et de chiffre suivi d'un point, i.e. dzea8d9z. ou encore etu.univ.
     * * `[a-zA-Z]{1,}` : Une suite de lettre minuscule ou majuscule
     * * `$` : Fin de la RegEx
     */   
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,

    /**
     * On vérifie que le numéro de téléphone entré commence ou non par un `+`, et que ce qui suit ne comporte que des chiffres
     * 
     * ---
     * ---
     * 
     * Explication de la RegEx :
     * 
     * ```
     * /^[+]\d+$/
     * ```
     * 
     * * `^` : Début de la regex
     * * `[+]` : On vérifie que l'on a bien un `+`
     * * `\d+` : On vérifie que l'on a bien un ou plusieurs chiffres
     * * `$` : Fin de la regex
     */
    phone: /^[+]\d+$/,
}