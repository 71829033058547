/**
 * @brief Composant permettant l'affichage d'un loarder.
 * @returns Composant permettant l'affichage d'un loarder.
 */
const Loader = (props: {className: string}) => {
    let className: string = `${props.className} loader`;

    return (
        <div className={className}>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
        </div>
    );
}

export default Loader;
